import Strings from "../../../shared/strings";
import {DataTableRowState} from "../enums";
import {ColumnSchema} from "../schemata";
import DataTableRow from "./DataTableRow";
import {DataServices as Ds, FilterSettings} from "../../../shared/data-services/DataServices";

export type GenericDataTable = AbstractDataTable<DataTableRow<any>, any>;

export abstract class AbstractDataTable<RowType extends DataTableRow<DtoType>, DtoType extends {id: string}> {
	private readonly name: string;
	private readonly formattingRules: any;
	private readonly startCell: CellLocation;
	protected columnsSchema: ColumnSchema[];
	protected uniqueColumnIndex: number;
	protected rows: RowType[];
	protected userModified: boolean = false;
	protected constructor(
		name: string,
		formattingRules: any,
		startCell: CellLocation,
		columnsSchema: ColumnSchema[],
		uniqueColumnIndex: number,
		rows: RowType[],
	) {
		this.name = name;
		this.formattingRules = formattingRules;
		this.startCell = startCell;
		this.columnsSchema = columnsSchema;
		this.uniqueColumnIndex = uniqueColumnIndex;
		this.rows = rows;
		this.columnsSchema = columnsSchema;
		this.userModified = false;
	}

	abstract refresh(filter?: FilterSettings): void;

	abstract async uploadInput(progressCallback: Function): Promise<void>;

	abstract getService(): any;

	protected abstract createRow(newData: DtoType | string[]): RowType;

	appendNewRowsFromDtos(newObjects: DtoType[]): void {
		this.clear();
		newObjects.forEach(object =>
			this.rows.push(this.createRow(object))
		);
	}

	updateSchema(columnsSchema: ColumnSchema[]){
		this.columnsSchema = columnsSchema;
	}

	getDropdownOptions(colIndex: number): string[] {
		const options = this.columnsSchema?.[colIndex].options ?? [];
		if (options === "STACKS") {
			return Ds.StacksService.getLoadedStacksForCurrentActivity().map(stack => stack.name);
		} else if (options === "CONTACTS") {
			return Ds.ContactsService.getLoadedContactsForCurrentActivity().map(contact => contact.email);
		} else {
			return options;
		}
	}

	getColumnType(colIndex: number): string | undefined {
		return this.columnsSchema?.[colIndex].type;
	}

	protected addNewRowFromRawData(newRow: string[]): void {
		this.rows.push(this.createRow(newRow));
	}

	private validateTable() {
		this.rows.forEach(row => row.validateAttributes());
		this.validateColumnProperties();
	}

	private validateColumnProperties() {
		for (let colIndex = 0; colIndex < this.columnsSchema.length; colIndex++) {
			this.enforceRequiredColumn(colIndex);
			this.enforceNonupdatability(colIndex);
		}
	}

	private enforceRequiredColumn(colIndex: number) {
		if (this.columnIsRequired(colIndex)) {
			this.rows.forEach(row => {
				if (row.cellEmpty(colIndex)) {
					row.setValidationMessageForCell(colIndex, Strings.tables.errors.mustBeFilled);
				}
			});
		}
	}

	private enforceNonupdatability(colIndex: number) {
		if (this.columnIsStatic(colIndex)) {
			this.rows.forEach(row => {
				if (row.cellModified(colIndex)) {
					row.setValidationMessageForCell(colIndex, Strings.tables.errors.noEdit);
				}
			});
		}
	}

	columnIsStatic(colIndex: number) {
		return !this.columnsSchema[colIndex].updateData;
	}

	columnIsRequired(colIndex: number) {
		return this.columnsSchema[colIndex].required;
	}

	columnIsCreatable(colIndex: number) {
		return this.columnsSchema[colIndex].createData;
	}

	updateRows(newRows: RowData[]) {
		this.removeRows((row) => row.isNew());
		for (const newRow of newRows) {
			if (newRow.id) {
				this.updateExistingRowFromRawData(newRow);
			} else {
				this.addNewRowFromRawData(newRow.rawCellData);
			}
		}
		this.removeRows((row) => row.isEmpty());
		this.validateTable();
		this.checkUserModified();
	}

	updateRowsFromDtos(newDtos: DtoType[]): void {
		for (const newDto of newDtos) {
			if (this.getRowByDbId(newDto.id)) {
				this.updateExistingRowFromDto(newDto);
			} else {
				this.rows.push(this.createRow(newDto));
			}
		}
	}

	private checkUserModified() {
		this.userModified = this.rowCountByType([
			DataTableRowState.CREATE_DATA,
			DataTableRowState.ERROR,
			DataTableRowState.UPDATE_DATA,
		]) + this.rowsMarkedForDeletionCount() > 0;
	}

	private removeRows(criteria: (row: RowType) => boolean) {
		let checkRowIndex = 0;
		while (checkRowIndex < this.rows.length) {
			if (criteria(this.rows[checkRowIndex])) {
				this.rows.splice(checkRowIndex, 1);
			}
			else {
				checkRowIndex++;
			}
		}
	}

	private updateExistingRowFromRawData(newRowData: RowData) {
		const matchingRow = this.getRowByDbId(newRowData.id!);
		if (matchingRow) {
			matchingRow.updateCellsFromNewRow(newRowData.rawCellData);
			if (newRowData.markedForDeletion) {
				matchingRow.markForDeletion();
			}
			else {
				matchingRow.unmarkForDeletion();
			}
		}
	}

	private updateExistingRowFromDto(newDto: DtoType) {
		const matchingRow = this.getRowByDbId(newDto.id);
		if (matchingRow) {
			matchingRow.updateCellsFromDto(newDto);
		}
	}

	getRowData(): RowData[] {
		return this.rows.map(row => ({
			id: row.getId() ?? undefined,
			rawCellData: row.getRawCellValues(),
			markedForDeletion: row.isMarkedForDeletion()
		}));
	}

	private getRowByDbId(dbId: string) {
		return this.rows.find(row => row.getId() === dbId);
	}

	getColumnCount() {
		return this.columnsSchema.length;
	}

	getColumnNames() {
		return this.columnsSchema.map(item => item.displayName);
	}

	hasErrors() {
		return this.rows.some(row => row.hasErrors());
	}

	getRowCount() {
		return this.rows.length;
	}

	getUniqueColumnIndex() {
		return this.uniqueColumnIndex;
	}

	clear() {
		this.rows = [];
		this.userModified = false;
	}

	rowCountByType(testState: DataTableRowState | DataTableRowState[]) {
		if (Array.isArray(testState)) {
			return this.rows.filter(row => testState.includes(row.getState())).length;
		}
		else {
			return this.rows.filter(row => row.getState() === testState).length;
		}
	}

	getRowState(rowIndex) {
		return this.rows[rowIndex].getState();
	}

	getValidationMessageAtLocation(cellLocation: CellLocation) {
		return this.rows[cellLocation.row].getValidationMessageForCell(cellLocation.col);
	}

	attributeUpdatedAtLocation(cellLocation: CellLocation): boolean {
		return this.rows[cellLocation.row].cellWasUpdated(cellLocation.col);
	}

	getName() {
		return this.name;
	}

	getFormattingRules() {
		return Object.assign({}, this.formattingRules);
	}

	getStartCellLocation() {
		return Object.assign({}, this.startCell);
	}

	rawCellValueAt(cellLocation: CellLocation) {
		return this.rows[cellLocation.row].getCellValue(cellLocation.col);
	}

	rowMarkedForDeletion(rowIndex: number): boolean {
		return this.rows[rowIndex].isMarkedForDeletion();
	}

	rowsMarkedForDeletionCount(): number {
		return this.rows.reduce((accum, row) => accum + (row.isMarkedForDeletion() ? 1 : 0), 0)
	}

	rowIsDuplicate(rowIndex: number): boolean {
		return this.rows[rowIndex].isDuplicate();
	}

	isDirty() {
		return this.userModified;
	}
}

export interface RowData {
	id?: string;
	rawCellData: string[];
	markedForDeletion: boolean;
}

export interface CellLocation {
	col: number;
	row: number;
}

export default GenericDataTable;