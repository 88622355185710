// +-------------------------------------+
// | File containing strings for project |
// +-------------------------------------+

// Strings that are displayed to the user in the app
const strings = {
    host: "https://s.flinker.app/",
    website: "https://flinker.app/",
    tables: {
        infoHeader: "Info",
        infoCellStatus: {
            new: "Neu",
            error: "Fehler",
            edited: "Ändern",
            default: "",
            toDelete: "Löschen"
        },
        deleteOptionHeader: "Löschen",
        deleteOption: "Löschen",
        errors: {
            rowGeneric: "Fehlerhafte Zeile",
            cellGeneric: "Fehlerhafte Eingabe",
            rowIsDupe: "Diese Zeile enthält Elemente, die bereits in der Tabelle vergeben wurden.",
            noEdit: "Dieses Feld darf nicht bearbeitet werden.",
            mustBeFilled: "Dieses Feld muss ausgefüllt werden.",
            attributes: {
                counterId: "",
                name: "",
                stackName: "Stack nicht gefunden",
                assignee: "Zugehörigen Kontakt nicht gefunden",
                startAt: "Das Datum ist ungültig. Ein Datum muss eins der folgenden Formate aufweisen: TT.MM.JJJJ HH:MM, TT.MM.JJJJ, TT.MM",
                createdAt: "Das Datum ist ungültig. Ein Datum muss eins der folgenden Formate aufweisen: TT.MM.JJJJ HH:MM, TT.MM.JJJJ, TT.MM",
                content: "",
                delete: "",
                email: "Die Email-Adresse hat ein ungültiges Format.",
                open: "Nur die Eingaben \"Offen\" und \"Erledigt\" sind gültig.",
                activities: "Activität nicht gefunden",
                status: "",
                label: "",
                type: "",
            }
        }
    },
    dialogs: {
        confirmLoseData: {
            title: "Achtung!",
            subtext: "Sicher, dass du fortfahren willst?",
            text: "Nicht geprüfte Änderungen und Eingaben werden verworfen.",
            confirm: "Fortfahren",
            cancel: "Abbrechen"
        },
        clearSheet: {
            title: "Daten Löschen",
            text: "Willst du alle Daten aus dem aktuellen Blatt löschen? Hochgeladene Änderungen können weiterhin abgerufen werden.",
            confirm: "Fortfahren",
            cancel: "Abbrechen"
        },
        proAlert: {
            titleLine1: "Unbegrenzt nutzen.",
            titleLine2: "Flinker PRO.",
            text: "Setze die Aktivität \"{0}\" auf PRO, um weitere Cards zu erstellen.",
            goProWebLink: "AUF PRO SETZEN",
            learnMoreLink: "Mehr erfahren",
        }
    },
    userProfile: {
        headerText: "Mein Account",
        createdActivity: "von dir erstellt",
        invitedToActivity: "eingeladen",
        activities: "Aktivitäten",
        myActivity: "",
        loadingActivity: "Lade Aktivität...",
        createAccount: "Erstelle ein Business Konto",
        profileExplanation: "Damit kannst du deinem Team Aktivitäten und Aufgaben zuweisen.",
        continue: "Weiter",
    },
    viewUI: {
        messages: {
            entryError: "Fehler bei der Synchronisierung!",
            stackError: "Fehler bei der Synchronisierung!",
            stackCreateSuccess: "Erstellen des ExcelStacks {0} erfolgreich.",
            entryCreateError: "Fehler beim Erstellen des Eintrags.",
            entryCreateSuccess: "Erstellen des Eintrags \"{0}\" erfolgreich.",
            entryUpdateSuccess: "Aktualisierung des Eintrags \"{0}\" erfolgreich.",
            entryDeleteSuccess: "Löschen des Eintrags \"{0}\" erfolgreich.",
            upgradeToProForMoreCards: "Du hast {0} neue Cards eingetragen. Um mehr als {1} Cards auf einmal zu erstellen, ist eine PRO-Aktivität erforderlich.",
            moreInfoProButton: "PRO holen",
            noProError: "Hol dir Flinker Pro, um mehr als 99 Cards in einer Aktivitat zu erstellen."
        },
        changeMessages: {
            rowSg: "Zeile",
            rowPl: "Zeilen",
            error: "mit Fehler",
            added: "neu hinzugefügt",
            edited: "bearbeitet",
            toBeDeleted: "zum Löschen markiert",
        },
        sharedUi: {
            checkAndUploadData: {
                changesHeader: "Änderungen",
                validateButton: "Prüfen",
                uploadButton: "Speichern",
            },
            viewCommandBar: {
                reloadButton: "Neu laden",
                infoButton: "Info"
            }
        },
        cards: {
            callout: {
                header: "Cards",
                body: "Erstelle eine Card, indem du eine neue Zeile hinzufügst.",
            },
            dateFilterHeader: "Datumsfilter",
            stackFilterHeader: "Stackfilter",
            contactFilterHeader: "Kontaktfilter",
            dateFilterPlaceholder: "Zeitraum auswählen",
            stackFilterPlaceholder: "Stack auswählen",
            contactFilterPlaceholder: "Kontakt auswählen",
            stackFilterAllStacks: "Alle Stacks",
            contactFilterAllContacts: "Alle Kontakte",
            loadMoreCards: "Mehr laden",
            allCardsLoaded: "Alle Cards geladen",
            cardCount: "{0} Cards",
            dateFilters: {
                noOption: "Alle Daten",
                lastHour: "Letzte Stunde",
                tomorrow: "Morgen",
                today: "Heute",
                yesterday: "Gestern",
                nextWeek: "Nächste Woche",
                thisWeek: "Diese Woche",
                lastWeek: "Letzte Woche",
                nextMonth: "Nächsten Monat",
                thisMonth: "Diesen Monat",
                lastMonth: "Letzten Monat",
                nextYear: "Nächstes Jahr",
                thisYear: "Dieses Jahr",
                lastYear: "Letztes Jahr",
            },
        },
        stacks: {
            callout: {
                header: "Stacks",
                body: `Ein Stack ist eine Sammlung von Cards.
                 Damit du den Überblick behältst, wird jeder deiner Einträge einem Stack zugeordnet.`,
            }
        },
        contacts: {
            callout: {
                header: "Mitglieder",
                body: `Lade andere Personen ein, indem du eine neue Zeile mit der gewünschten E-Mail-Adresse
                 hinzufügst. Eingeladene Kontakte erhalten eine Benachrichtigung. Nachdem sich deine
                 eingeladenen Kontakte registriert haben, kannst du ihnen Cards zuweisen.`,
            }
        }
    },
    login: {
        companyNameExample: "z.B Meine GmbH oder Unser Ingenieurbüro",
        askCompanyName: "Wie lautet der Name deines Unternehmens oder deiner Tätigkeit?",
        companyLoading: "Lade Unternehmen...",
        companyName: "Name des Unternehmens",
        completeLogin: "Anmeldung abschließen",
    },
    spinners: {
        dbLoading: "Wird synchronisiert...",
        uploadsPendingPlu: "Synchronisierung... {0} Einträge verbleibend. {1} Fehler.",
        uploadsPendingSing: "Synchronisierung... {0} Eintrag verbleibend. {1} Fehler.",
        officeOp: "Office lädt..."
    }
};

export default strings;