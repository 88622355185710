// +---------------------------------------------------+
// | File containing mappings between static resources |
// | such as enums, colors, schemas, etc.              |
// +---------------------------------------------------+

import { DataTableRowState } from "./enums";
import Strings from "../../shared/strings";
import Colors from "./colors";

// Mapping from row state to display string
export const infoCellDisplayStringFromRowState = {
	[DataTableRowState.UNCHANGED]: Strings.tables.infoCellStatus.default,
	[DataTableRowState.UPDATE_DATA]: Strings.tables.infoCellStatus.edited,
	[DataTableRowState.CREATE_DATA]: Strings.tables.infoCellStatus.new,
	[DataTableRowState.ERROR]: Strings.tables.infoCellStatus.error,
};

// Mapping from row state to display string
export const infoCellColorFromRowState = {
	[DataTableRowState.UNCHANGED]: Colors.status.default,
	[DataTableRowState.UPDATE_DATA]: Colors.status.edited,
	[DataTableRowState.CREATE_DATA]: Colors.status.new,
	[DataTableRowState.ERROR]: Colors.status.error,
};

export const infoCellTextColorFromRowState = {
	[DataTableRowState.UNCHANGED]: Colors.statusText.default,
	[DataTableRowState.UPDATE_DATA]: Colors.statusText.edited,
	[DataTableRowState.CREATE_DATA]: Colors.statusText.new,
	[DataTableRowState.ERROR]: Colors.statusText.error,
};