import * as React from "react";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import { Overlay, ActionButton } from "office-ui-fabric-react";
import { IStackProps, Stack } from 'office-ui-fabric-react/lib/Stack';


function ExcelLoadingOverlay(props) {
    const { label, loading, excel } = props;
    return (
        <Stack styles={{ root: { height: 20 } }}>
            {loading && <Spinner label={label} size={SpinnerSize.small} ariaLive="assertive" labelPosition="left" />}
        </Stack>
    );
}

export default ExcelLoadingOverlay;