import React, { useState, useEffect } from 'react';
import { withAuthenticator, Authenticator, SignIn, Greetings, ConfirmSignUp } from 'aws-amplify-react'; // or 'aws-amplify-react-native';
import Amplify, { Auth } from 'aws-amplify';
import { I18n, Translations } from '@aws-amplify/core';
import awsconfig from './data-services/aws-exports';
import { Image, Stack, PrimaryButton, Text } from 'office-ui-fabric-react';
import flinkerLogo from "./images/flinker-icon-black.png";
import CustomTheme from "./theme.js";
import { useLocation, useParams } from "react-router-dom";
import { Hub, Logger } from "@aws-amplify/core";
import { Card } from '@uifabric/react-cards';
import LogoWithText from "./LogoWithText";
import '@aws-amplify/ui/dist/style.css';
import { onAuthUIStateChange } from '@aws-amplify/ui-components'


Amplify.configure(awsconfig);
I18n.setLanguage('de');

// Overwrite error messages
I18n.putVocabularies({
    de: {
        "Custom auth lambda trigger is not configured for the user pool.":
            "Passwort darf nicht leer sein",
        "Password cannot be empty": "Passwort darf nicht leer sein",
        "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
            "Passwort nicht lang genug",
        "Password did not conform with policy: Password not long enough":
            "Passwort nicht lang genug",
        "Invalid email address format.": "Email ungültig",
        "Attribute value for family_name must not be null": "Last Name required",
        "Username/client id combination not found.": "Nutzer nicht gefunden",
        "Incorrect username or password.": "Email oder Passwort sind ungültig",
        "Forgot your password?": "Passwort vergessen?",
        "No account?": "Keinen Account?",
        "Have an account?": "Hast du einen Account?",
        "Username *": "Email *",
        "Verification code": "Verifizierungs-Code",
        "Enter code": "Code aus Email eingeben",
        "New password": "Neues Passwort",
        "Enter your new password": "Neues Passwort eingeben",
        "Enter your email": "Gib deine E-Mail ein",
        "Geben Sie Ihr Passwort ein": "Gib dein Passwort ein"

    },
});

const signUpConfig = {
    header: 'Konto erstellen',
    hideAllDefaults: true,
    defaultCountryCode: '1',
    signUpFields: [
        {
            label: 'Email',
            key: 'username',
            required: true,
            displayOrder: 1,
            type: 'string'
        },
        {
            label: 'Passwort',
            key: 'password',
            required: true,
            displayOrder: 2,
            type: 'password'
        }
    ]
};
const usernameAttributes = 'email';

const AuthenticatorWrapper = (props) => {

    const { renderApp: App } = props;
    let [user, setUser] = useState(null);
    let [authState, setAuthState] = useState('signIn');

    let updateUser = async authState => {
        setAuthState(authState);
        try {
            let user = await Auth.currentAuthenticatedUser()
            setUser(user);
            props.onChange(user);
        } catch {
            setUser(null)
        }
    }

    useEffect(() => {
        Hub.listen('auth', updateUser) // listen for login/signup events
        updateUser() // check manually the first time because we won't get a Hub event
        return () => Hub.remove('auth', updateUser) // cleanup
    }, []);


    class MyConfirmSignUp extends ConfirmSignUp {
        render() {
            return (
                <>
                    {this.props.authState === 'confirmSignUp' &&
                        <Card
                            aria-label="card"
                            vertical
                            styles={{
                                root: {
                                    padding: 40,
                                    backgroundColor: 'white',
                                    margin: 'auto'
                                }
                            }}>
                            <Text block>Überprüfe deine E-Mails, wir haben dir einen Link zur Verifizierung gesendet.</Text>
                            <PrimaryButton
                                text="Zurück zur Anmeldung"
                                onClick={() => window.location.reload()}
                                styles={{
                                    root: {
                                        borderRadius: 50
                                    }
                                }} />
                        </Card>}
                </>
            );
        }
    }

    const AppWrapper = () => {
        return (authState === 'signedIn' ? <App /> : null);
    }

    return (
        <Authenticator
            authState={window.location.pathname.includes('signup') ? 'signUp' : 'signIn'}
            onStateChange={(authState) => setAuthState(authState)}
            hide={[Greetings, ConfirmSignUp]}
            signUpConfig={signUpConfig}
            usernameAttributes={usernameAttributes}
            theme={CustomTheme} >
            <AppWrapper></AppWrapper>
            <MyConfirmSignUp override={'ConfirmSignUp'} />
        </Authenticator >
    );
};



export default AuthenticatorWrapper