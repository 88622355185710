import {API, graphqlOperation} from "aws-amplify";
import {listContacts, getProfile, inviteContact} from "./graphql";
import {GraphQLResult} from "@aws-amplify/api-graphql";
import ContactDto, {ContactServerResponseDto, InputContact} from "./ContactDto";
import {DataServices, DataServices as Ds} from "./DataServices";
import StackDto from "./StackDto";

class ContactsService {
    private contactsByActivityId: Record<string, ContactDto[]> = {};
    private companyProfileContact: ContactDto | null = null;
    private lastActionResponseMessages: any[] = [];
    constructor() {}

    allLoaded() {
        return true;
    }

    clearCache() {
        this.contactsByActivityId = {};
    }

    async getCompanyProfile() {
        if (!this.companyProfileContact) {
            await this.loadCompanyProfile();
        }
        return this.companyProfileContact ?? null;
    }

    getLoadedContactsForCurrentActivity(): ContactDto[] {
        const activeActivityId = Ds.ActivitiesService.getActiveActivityId();
        return this.contactsByActivityId[activeActivityId] ?? [];
    }

    getLoadedContactById(contactId: string): ContactDto | null {
        for (const contactList of Object.values(this.contactsByActivityId)) {
            const foundContact = contactList.find(card => card.id === contactId);
            if (foundContact) {
                return foundContact;
            }
        }
        return null;
    }

    async getContactsForCurrentActivity(): Promise<ContactDto[]> {
        const activeActivityId = Ds.ActivitiesService.getActiveActivityId();
        return this.getContactsForActivity(activeActivityId);
    }

    async getContactsForActivity(activityId: string): Promise<ContactDto[]> {
        if (!this.contactsByActivityId[activityId]) {
            await this.loadContactsForActivity(activityId);
        }
        return [...this.contactsByActivityId[activityId]];
    }

    async inviteContact(newContact: InputContact) {
        try {
            return API.graphql(graphqlOperation(inviteContact, {
                activityId: DataServices.ActivitiesService.getActiveActivityId(),
                email: newContact.email,
            }));
        } catch (err) {
            return err;
        }
    }

    async deleteContact(contactId: string) {
        //TODO
    }

    async removeContactFromActivity(activityId: string, contactId: string) {
        //TODO
    }

    getResponseMessages() {
        return this.lastActionResponseMessages.slice();
    }

    clearResponseMessages() {
        this.lastActionResponseMessages = [];
    }

    private async loadCompanyProfile() {
        const result = (await API.graphql(graphqlOperation(getProfile))) as GraphQLResult<any>;
        if (result.data?.getProfile) {
            this.companyProfileContact = this.serverDataToInternalDto(result.data.getProfile);
        }
        else {
            this.companyProfileContact = null;
        }
    }

    private serverDataToInternalDto(contactResponseObject: ContactServerResponseDto): ContactDto {
        const contact = {
            id: contactResponseObject.id,
            name: contactResponseObject.name
        };
        const parsedAppendix = JSON.parse(contactResponseObject.appendix)
        Object.assign(contact, parsedAppendix);
        return contact as ContactDto;
    }

    private async loadContactsForActivity(activityId: string) {
        const result = (await API.graphql(graphqlOperation(listContacts, { activityId }))) as GraphQLResult<any>;
        if (result.data.listContacts) {
            console.log(result.data.listContacts);
            this.contactsByActivityId[activityId] = [];
            for (const contact of result.data.listContacts.items) {
                this.contactsByActivityId[activityId].push(this.serverDataToInternalDto(contact));
            }
        }
    }
}

export default ContactsService;