
import "office-ui-fabric-react/dist/css/fabric.min.css";
import App from "./App";
import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import { registerIcons } from 'office-ui-fabric-react/lib/Styling';
import * as React from "react";
import * as ReactDOM from "react-dom";
import { bootstrapDataServices } from "../../shared/data-services/DataServices";
import Unauthenticated from "../../shared/Unauthenticated";
import Authenticator from "../../shared/Authenticator";

initializeIcons();
bootstrapDataServices({ cardLimit: 200 })

Office.initialize = () => { };

Office.onReady(() => {
    console.log("Office ready!");
    ReactDOM.render(
        (
            <React.StrictMode>
                <Unauthenticated renderApp={() => <App />} />
            </React.StrictMode>
        ),
        document.getElementById("container")
    );
});


