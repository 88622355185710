import * as React from "react";
import awsconfig from "../../shared/data-services/aws-exports";
import Amplify, {Auth} from "aws-amplify";
import ExcelHeader from "../../shared/ExcelHeader";
import ExcelLoseDataDialog from "../../shared/ExcelLoseDataDialog";
import {Separator} from "office-ui-fabric-react/lib/Separator";
import ProAlert from "../../shared/ProAlert";
import ExcelCheckAndUploadData from "../../shared/ExcelCheckAndUpdateData";
import ExcelLoadingOverlay from "../../shared/ExcelLoadingOverlay";
import formatUnicorn from "format-unicorn/safe";
import "./App.css";
import RiskyRequestHandler from "./classes/RiskyRequestHandler";
import ExcelTableChangeMessages from "../../shared/ExcelTableChangeMessages";
import ExcelDbMessages from "../../shared/ExcelDbMessages";
import requestInteractor, {ExcelInteractor} from "./classes/ExcelInteractor";
import Strings from "../../shared/strings";
import {TableId} from "./schemata";
import {DataTableRowState} from "./enums";
import {DataServices, FilterSettings} from "../../shared/data-services/DataServices";
import ExcelCommandBar from "../../shared/ExcelCommandBar";
import ExcelPaneCards from "../../shared/ExcelPaneCards";
import ExcelPaneStacks from "../../shared/ExcelPaneStacks";
import ExcelPaneContacts from "../../shared/ExcelPaneContacts";
import CardsTable from "./classes/CardsTable";
import ActivityDto, {ActivityServerResponseDto, UploadActivity} from "../../shared/data-services/ActivityDto";
import ContactDto from "../../shared/data-services/ContactDto";
import StacksTable from "./classes/StacksTable";
import ContactsTable from "./classes/ContactsTable";
import RootActivityDto from "../../shared/data-services/RootActivityDto";
import ActivitiesPanel from "../../shared/ActivitiesPanel";
import {
    BrowserRouter as Router,
} from "react-router-dom";
import { removeContactFromActivity } from "../../shared/data-services/graphql";

Amplify.configure(awsconfig);
// To get claims in appsync
Amplify.configure({
    graphql_headers: async () => {
        return {Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()};
    },
    Storage: {
        AWSS3: {
            bucket: awsconfig.aws_content_delivery_bucket,
            region: awsconfig.aws_content_delivery_bucket_region,
        },
    },
});

interface AppProps {}

interface AppState {
    fatalError: boolean;
    initializing: boolean;
    loading: boolean;
    awaitingOffice: boolean;
    rootActivity: RootActivityDto | null;
    currentActivityId: string | null;
    profile: ContactDto | null;
    basicActivities: ActivityDto[];
    currentTableId: TableId;
    cardsFilter: FilterSettings;
    showProfile: boolean;
    showAccount: boolean;
    showWarningDialog: boolean;
    proAlertVisible: boolean;
    syncErrors: number;
    syncSuccesses: number;
    syncOutstanding: number;
}

class App extends React.Component<AppProps, AppState> {

    
    private excelInteractor: ExcelInteractor | null = null;
    private riskyRequestHandler: RiskyRequestHandler;
    private tableModifiedInExcel: boolean = false;
    private tables: {
        [TableId.cards]: CardsTable;
        [TableId.stacks]: StacksTable;
        [TableId.contacts]: ContactsTable;
    }
    constructor(props, context) {
        super(props, context);
        this.state = {
            fatalError: false,
            initializing: true,
            loading: true,
            awaitingOffice: true,
            rootActivity: null,
            currentActivityId: null,
            profile: null,
            basicActivities: [],
            currentTableId: TableId.cards,
            cardsFilter: {},
            showProfile: false,
            showAccount: false,
            showWarningDialog: false,
            proAlertVisible: true,
            syncErrors: 0,
            syncSuccesses: 0,
            syncOutstanding: 0,
        };
        this.tables =  {
            [TableId.cards]: new CardsTable(),
            [TableId.stacks]: new StacksTable(),
            [TableId.contacts]: new ContactsTable(),
        };
        this.riskyRequestHandler = new RiskyRequestHandler((newVisibility) =>
            this.changeWarningDialogVisibility(newVisibility),
        );
    }

    componentDidMount() {
        this.startup();
    }

    async startup() {
        const { rootActivity, activities, company } = await this.getActivityData();
        if (!rootActivity) {
            this.setState({fatalError: true});
            return;
        }
        await this.currentTable().selectSchemaForActivity();
        this.excelInteractor = await requestInteractor(this.currentTable());
        this.setState({
            rootActivity: rootActivity,
            profile: company,
            basicActivities: activities,
            currentActivityId: rootActivity.id,
        },
        async () => {
            console.log("startup")
            await this.loadInitialContactsAndStacks();
            await this.currentTable().refresh();
            await this.renderSelectedTableInExcel();
            this.setState({
                awaitingOffice: false,
                loading: false,
                initializing: false,
            });
        });
    }


    async signOut() {
        await Auth.signOut();
        this.props.onStateChange('signIn');
        window.addEventListener('unload', function (event) {
            localStorage.clear();
        });
    }

    async getActivityData() {
        const rootActivity = await DataServices.ActivitiesService.getRootActivity();
        const activities = await DataServices.ActivitiesService.getAllActivities();
        const company = await DataServices.ContactsService.getCompanyProfile();
        return { rootActivity, activities, company };
    }

    async loadInitialContactsAndStacks() {
        await DataServices.ContactsService.getContactsForActivity(this.state.currentActivityId as string);
        await DataServices.StacksService.getStacksForActivity(this.state.currentActivityId as string);
    }

    notifyDataSyncProgress(errorCount, successCount, outstanding) {
        this.setState({
            syncErrors: errorCount,
            syncSuccesses: successCount,
            syncOutstanding: outstanding,
        });
    }


    async selectTable(tableId) {
        this.setState({currentTableId: tableId, loading: true});
        await this.tables[tableId].refresh(this.state.cardsFilter);
        await this.renderSelectedTableInExcel();
        this.setState({loading: false});
    }

    async checkNewExcelInput() {
        this.setState({loading: true});
        await this.updateTableFromCurrentExcelInput();
        await this.renderSelectedTableInExcel();
        this.setState({loading: false});
    }

    async validateTableInExcel() {
        await this.setState({awaitingOffice: true}, async () => {
            await this.excelInteractor?.validateDataTable(
                this.tables[this.state.currentTableId],
                async () => {/* this.tableModifiedInExcel = true */},
            );
            this.tableModifiedInExcel = false;
            this.setState({awaitingOffice: false});
        });
    }

    
    async renderSelectedTableInExcel() {
        await this.setState({awaitingOffice: true}, async () => {
            await this.excelInteractor?.renderDataTable(
                this.tables[this.state.currentTableId],
                async () => {/* this.tableModifiedInExcel = true */},
            );
            this.tableModifiedInExcel = false;
            this.setState({awaitingOffice: false});
        });
    }

    async addRowRangeFromCurrentTableToExcel(rowStart, rowEnd) {
        await this.setState({awaitingOffice: true}, async () => {
            try {
                await this.excelInteractor?.renderRowRangeFromTable(
                    this.currentTable(),
                    rowStart,
                    rowEnd,
                );
                this.setState({awaitingOffice: false});
                this.tableModifiedInExcel = false;
            } catch (e) {
                console.log(e);
            }
        });
    }

    async updateTableFromCurrentExcelInput() {
        const userInput = await this.excelInteractor!.getRawRowDataWithIds();
        this.currentTable().updateRows(userInput);
    }

    currentTable() {
        return this.tables[this.state.currentTableId];
    }

    async updateCardsFilter(newParams: FilterSettings) {
        console.log("updateCardsFilter")
        await this.currentTable().refresh(newParams);
        await this.renderSelectedTableInExcel();
        this.setState({cardsFilter: newParams});
    }

    toggleShowAccount() {
        this.setState({showAccount: !this.state.showAccount});
    }

/*     async createActivity(name) {
        const activityInput = { name}
        const activity = await DataServices.ActivitiesService.createActivity(activityInput as UploadActivity);
        const activities = await DataServices.ActivitiesService.getAllActivities();
        this.setState({basicActivities: activities});
    } */

    selectActivityWithId(activityId) {
        DataServices.ActivitiesService.setActiveActivityId(activityId);
        DataServices.CardsService.clearResponseMessages();
        this.toggleShowAccount();
        this.setState({currentActivityId: activityId, cardsFilter: {}},
            async () => {
            await this.currentTable().selectSchemaForActivity();
            for (const tableId in this.tables) {
                await this.tables[tableId].refresh();
            }
            await this.renderSelectedTableInExcel();
        });
    }

    closeActivityPanel() {
        this.setState({showAccount: false});
    }

    getLoadingOverlayMessage() {
        if (this.state.syncOutstanding > 0) {
            const completedSyncs = this.state.syncSuccesses + this.state.syncErrors;
            const statusMessage =
                completedSyncs === 1
                    ? Strings.spinners.uploadsPendingSing
                    : Strings.spinners.uploadsPendingPlu;
            return formatUnicorn(statusMessage, [
                this.state.syncOutstanding,
                this.state.syncErrors,
            ]);
        } else if (this.state.loading || this.state.initializing) {
            return Strings.spinners.dbLoading;
        } else {
            return Strings.spinners.officeOp;
        }
    }

    changeWarningDialogVisibility(newVisibility) {
        this.setState({showWarningDialog: newVisibility});
    }

    currentActivity() {
        return this.state.basicActivities.find(act => this.state.currentActivityId === act.id);
    }

    async loadMoreCards() {
        this.setState({loading: true}, async () => {
            await DataServices.CardsService.loadMoreCardsForActivity(this.state.currentActivityId!);
            this.tables.cards.updateFromLoadedCards();
            this.setState({loading: false}, () => {
                this.renderSelectedTableInExcel();
            });
        });
    }

    getResponseMessages() {
        return this.currentTable().getService().getResponseMessages();
    }

    enforceTableWasChecked(callback: Function) {
        this.riskyRequestHandler.req(callback, () => this.tableModifiedInExcel);
    }

    enforceCompletelyCleanTable(callback: Function) {
        this.riskyRequestHandler.req(callback, () => {
            return this.currentTable().isDirty() || this.tableModifiedInExcel;
        });
    }

    uploadInputForCurrentTable() {
        this.setState({loading: true}, async () => {
            await this.currentTable().uploadInput((e, s, o) => this.notifyDataSyncProgress(e, s, o));
            this.setState({loading: false}, () => this.refreshCurrentTable());
        });
    }

    refreshCurrentTable() {
        this.setState({loading: true}, async () =>{
            console.log("currenttable")
            await this.currentTable().refresh();
            this.setState({loading: false});
            await this.renderSelectedTableInExcel();
        });
    }

    render = () => {
        if (this.state.initializing) {
            return <ExcelLoadingOverlay loading={true} excel={this.excelInteractor} label={this.getLoadingOverlayMessage()} />;
        }

        const addedItemCount = this.currentTable().rowCountByType(DataTableRowState.CREATE_DATA);
        const editedItemCount = this.currentTable().rowCountByType(DataTableRowState.UPDATE_DATA);
        const errorItemCount = this.currentTable().rowCountByType(DataTableRowState.ERROR);
        const canSaveTableData =
            errorItemCount === 0 &&
            addedItemCount + editedItemCount > 0;

        return (
            <Router>
                <ExcelLoseDataDialog
                    show={this.state.showWarningDialog}
                    onConfirm={() => this.riskyRequestHandler.continueAction()}
                    onCancel={() => this.riskyRequestHandler.ignoreAction()}
                    warningMessage={Strings.dialogs.confirmLoseData.text}
                />
                <ExcelLoadingOverlay 
                    loading={this.state.awaitingOffice || this.state.loading || this.state.syncOutstanding > 0}
                    label={this.getLoadingOverlayMessage()} />
                <ExcelHeader
                    activityName={this.currentActivity()!.name}
                    activityIsMine={this.currentActivity()!.isMine}
                    onClick={() => this.toggleShowAccount()}/>
                <Separator />
                <ActivitiesPanel
                    web={false}
                    activities={this.state.basicActivities}
                    hide={() => this.toggleShowAccount()}
                    profileId={this.state.profile!.id}
                    name={this.state.profile!.name}
                    email={this.state.profile!.email}
                    selectActivityById={(id) => this.selectActivityWithId(id)}
                    selectPremium={() => window.open(Strings.host)}
                    visible={this.state.showAccount}
                    onCreate={(name)=> {}}
                    onSignOut={() => this.signOut()}
                />
                <ExcelCommandBar
                    isRoot={this.state.currentActivityId == this.state.rootActivity?.id}
                    loading={this.state.awaitingOffice || this.state.loading || this.state.syncOutstanding > 0}
                    chooseCards={() => this.enforceCompletelyCleanTable(() => this.selectTable(TableId.cards))}
                    chooseStacks={() => this.enforceCompletelyCleanTable(() => this.selectTable(TableId.stacks))}
                    chooseContacts={() => {
                        //this.enforceTableWasChecked(() => this.selectTable(TableId.contacts));
                        window.open(Strings.host+this.currentActivity()!.id);
                    }}
                    currentlySelected={this.state.currentTableId}
                />
                <ExcelPaneCards
                    isRoot={this.state.currentActivityId == this.state.rootActivity?.id}
                    loading={this.state.awaitingOffice || this.state.loading || this.state.syncOutstanding > 0}
                    visible={this.state.currentTableId === TableId.cards}
                    loadedItemCount={this.currentTable().getRowCount()}
                    activeFilter={this.state.cardsFilter}
                    updateFilter={(filterParams) => this.updateCardsFilter(filterParams)}
                    loadMoreItems={() => this.enforceCompletelyCleanTable(() => this.loadMoreCards())}
                    activityId={this.state.currentActivityId}
                    allLoaded={DataServices.CardsService.allLoadedInActivity(this.state.currentActivityId!)}
                />
{/*                 <ExcelPaneStacks
                    visible={this.state.currentTableId === TableId.stacks}
                    loadedItemCount={this.currentTable().getRowCount()}
                    refreshItems={() => this.enforceCompletelyCleanTable(() => this.refreshCurrentTable())}
                /> */}
                <ExcelPaneContacts
                    visible={this.state.currentTableId === TableId.contacts}
                    loadedItemCount={this.currentTable().getRowCount()}
                    refreshItems={() => this.enforceCompletelyCleanTable(() => this.refreshCurrentTable())}
                />
                <ExcelCheckAndUploadData
                    loading={this.state.awaitingOffice || this.state.loading || this.state.syncOutstanding > 0}
                    saveInput={() => this.uploadInputForCurrentTable()}
                    checkInput={() => this.checkNewExcelInput()}
                    canSave={canSaveTableData}
                />
                <ExcelTableChangeMessages
                    addedCount={addedItemCount}
                    editedCount={editedItemCount}
                    errorCount={errorItemCount}
                    toDeleteCount={this.currentTable().rowsMarkedForDeletionCount()}
                />
                <ExcelDbMessages messageItems={this.getResponseMessages()} />
                {false && this.state.currentTableId === TableId.cards && (
                    <ProAlert
                        visible={this.state.proAlertVisible}
                        activityId={this.currentActivity()!.id}
                        activityName={this.currentActivity()!.name}
                        onDismiss={() => this.setState({proAlertVisible: false})}
                        numCreatedCards={addedItemCount}
                    />
                )}
            </Router>
        );
    };
}

export default App;
