import {AttributeName, DataTableRowState} from "../enums";
import {ColumnSchema} from "../schemata";
import StacksTableRow from "./StacksTableRow";
import StackDto from "../../../shared/data-services/StackDto";
import {AbstractDataTable} from "./DataTable";
import {DataServices, DataServices as Ds} from "../../../shared/data-services/DataServices";

const stacksTableColumnsSchema: ColumnSchema[] = [
    {
        label: AttributeName.name,
        displayName: "Stack",
        required: true,
        updateData: true,
        createData: true,
    }
];

const stacksTableStyle = {
    style: "TableStyleLight18",
    showBandedRows: false
};

class StacksTable extends AbstractDataTable<StacksTableRow, StackDto> {
    constructor() {
        super(
            "Stacks_Tabelle",
            stacksTableStyle,
            {col: 0, row: 0},
            stacksTableColumnsSchema,
            0,
            []
        );
    }

    selectSchemaForActivity(){
	}

    async refresh() {
        Ds.StacksService.clearCache();
        const newStacks = await Ds.StacksService.getStacksForCurrentActivity();
        this.appendNewRowsFromDtos(newStacks);
    }

    async uploadInput(progressCallback: Function) {
        let syncErrors = 0;
        let syncSuccesses = 0;
        let syncOutstanding = this.rowCountByType([DataTableRowState.CREATE_DATA, DataTableRowState.UPDATE_DATA]);
        for (const row of this.rows) {
            try {
                const rowId = row.getId();
                if (!rowId) {
                    await Ds.StacksService.createStack(row.toInputStack());
                }
                else if (row.isMarkedForDeletion()) {
                    await Ds.StacksService.deleteStack(rowId);
                }
                else if (row.userModified()) {
                    await Ds.StacksService.updateStack(rowId, row.toInputStack());
                }
                syncSuccesses += 1;
            }
            catch (e) {
                syncErrors += 1;
            }
            syncOutstanding -= 1;
            progressCallback(syncErrors, syncSuccesses, syncOutstanding);
        }
    }

    getService() {
        return DataServices.StacksService;
    }

    protected createRow(newData: string[] | StackDto) {
        return new StacksTableRow(newData, stacksTableColumnsSchema);
    }
}

export default StacksTable;