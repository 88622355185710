class RiskyRequestHandler {
    constructor(changeDialogVisibility) {
        this.haltedAction = null;
        this.changeDialogVisibility = changeDialogVisibility;
    }

    req(requestedAction, returnsTrueOnBlock) {
        const wasHalted = returnsTrueOnBlock();
        if (wasHalted) {
            this.haltedAction = requestedAction;
            this.changeDialogVisibility(true);
		} else {
			requestedAction();
		}
	}

	continueAction() {
        const haltedActionTemp = this.haltedAction;
        this.hideAndReset();
        haltedActionTemp();
    }

    ignoreAction() {
        this.hideAndReset();
    }

    hideAndReset() {
        this.changeDialogVisibility(false);
        this.haltedAction = () => {};
    }
}

export default RiskyRequestHandler;