import {AttributeName, DataTableRowState} from "../enums";
import {validateAttribute} from "./AttributeValidator";
import {ColumnSchema} from "../schemata";
import {Decimal} from 'decimal.js';

export interface Cell {
	name: AttributeName;
	value: string;
	validationMessage: string;
	updated: boolean;
	type?: string;
	error: boolean;
}

export default abstract class DataTableRow<DtoType extends {id: string}> {
	protected markedAsDuplicate: boolean = false;
	protected invalid: boolean = false;
	protected state: DataTableRowState = DataTableRowState.CREATE_DATA;
	protected markedForDeletion: boolean = false;
	protected cells: Cell[];
	protected originalDto: DtoType | null = null;

	protected constructor(sourceData: DtoType | string[], columnSchema: ColumnSchema[]) {
		if (Array.isArray(sourceData)) {
			this.cells = sourceData.map((cell, index) => this.createCell(columnSchema[index].label, cell, columnSchema[index].type));
		}
		else {
			this.state = DataTableRowState.UNCHANGED;
			this.cells = columnSchema.map(column => this.createCell(column.label, sourceData[column.label] ?? "",column.type));
			this.originalDto = sourceData;
		}
	}

	createCell(name: AttributeName, data: string, type: string) {
		return {
			name: name,
			value: data,
			type: type,
			validationMessage: "",
			updated: false,
			error: false,
		};
	}

	validateAttributes() {
		if (this.userModified()) {
			this.resetValidationMarkers();
			this.validateCells();
			this.invalid = this.hasErrors();
		}
	}

	protected resetValidationMarkers() {
		this.invalid = false;
		this.unmarkAsDuplicate();
		this.cells.forEach((cell, index) => {
			this.setValidationMessageForCell(index, "");
			cell.error = false;
		});
	}

	protected validateCells() {
		this.cells.forEach((cell, index) => {
			const validationResult = validateAttribute(cell.name, cell.value);
			if (!validationResult.passed) {
				this.setValidationMessageForCell(index, validationResult.message);
				cell.error = true;
			}
		});
	}

	markAsDuplicate() {
		this.markedAsDuplicate = true;
	}

	unmarkAsDuplicate() {
		this.markedAsDuplicate = false;
	}

	isDuplicate() {
		return this.markedAsDuplicate;
	}

	getRawCellValues() {
		return this.cells.map(cell => cell.value);
	}

	getCellValue(cellIndex) {
		return this.cells[cellIndex].value;
	}

	isSame(value1, value2, type) {
		var tmpIsSame = false;
		if (value1 == value2)
			return true;
		
		if (!value1 || !value2)
			return false;

		if (type)
			if (type=='Number')
			{
				try{
					var tmpValue1= value1.toString();
					var tmpTalue2 = value2.toString();
					tmpValue1 = tmpValue1.replace(',','.')
					tmpTalue2 = tmpTalue2.replace(',','.')
					var num2 = new Decimal(tmpTalue2);
					var num1 = new Decimal(tmpValue1);
					if (num1.equals(num2))
						tmpIsSame = true;
				}
				catch(e){
					console.log('not valid number format')
				}
				
				
			}

		return tmpIsSame;
	}

	updateCellsFromNewRow(rawRowData) {
		this.cells.forEach((cell, i) => {
			//Compare value in excel
			var type = ''
			if (cell.type)
				type = cell.type
			const isSame = this.isSame(rawRowData[i],cell.value,type);
			if (!isSame)
				this.updateCellAtColumn(i, rawRowData[i]);
		});
	}

	updateCellsFromDto(newDto: DtoType) {
		this.cells.forEach((cell, i) => {
			// Compare value in excel with value from db, with i column scheme
			if (newDto?.[cell.name] !== cell.value) {
				this.updateCellAtColumn(i, newDto?.[cell.name]);
			}
		});
	}

	protected updateCellAtColumn(cellIndex, newValue) {
		this.cells[cellIndex].value = newValue;
		if (this.state === DataTableRowState.UNCHANGED) {
			this.cells[cellIndex].updated = true;
			this.state = DataTableRowState.UPDATE_DATA;
		}
	}

	setValidationMessageForCell(cellIndex, message) {
		this.cells[cellIndex].validationMessage = message;
		this.cells[cellIndex].error = message !== "";
	}

	getValidationMessageForCell(cellIndex) {
		return this.cells[cellIndex].validationMessage;
	}

	hasErrors() {
		return this.cells.some(cell => cell.error) || this.markedAsDuplicate || this.invalid;
	}

	getState() {
		if (this.hasErrors()) {
			return DataTableRowState.ERROR;
		}
		else if (this.isMarkedForDeletion()) {
			return DataTableRowState.UPDATE_DATA;
		}
		else {
			return this.state;
		}
	}

	protected updateState() {
		if (this.originalDto && !this.updatedCellsPresent()) {
			this.state = DataTableRowState.UNCHANGED
		}
		else {
			this.state = DataTableRowState.UPDATE_DATA;
		}
	}

	protected updatedCellsPresent() {
		return this.cells.some(cell => cell.updated);
	}

	protected oldValue(cellIndex: number) {
		return this.originalDto?.[this.cells[cellIndex].name] ?? undefined;
	}

	cellModified(cellIndex: number) {
		if (this.originalDto) {
			return this.cells[cellIndex].value !== this.oldValue(cellIndex);
		}
		return false;
	}

	userModified() {
		return this.state !== DataTableRowState.UNCHANGED;
	}

	cellWasUpdated(cellIndex) {
		return this.cells[cellIndex].updated;
	}

	isNew() {
		return this.state === DataTableRowState.CREATE_DATA;
	}

	isEmpty() {
		return this.cells.every((cell, i) => this.cellEmpty(i));
	}

	cellEmpty(cellIndex) {
		return this.cells[cellIndex].value === "";
	}

	getId(): string | null {
		return this.originalDto?.[AttributeName.id] ?? null;
	}

	markForDeletion() {
		this.markedForDeletion = true;
	}

	unmarkForDeletion() {
		this.markedForDeletion = false;
	}

	isMarkedForDeletion() {
		return this.markedForDeletion;
	}

	getCellByAttribute(attributeName: AttributeName): Cell | null {
		return this.cells.find(cell => cell.name === attributeName) ?? null;
	}

	getCellByName(attributeName: string): Cell | null {
		return this.cells.find(cell => cell.name === attributeName) ?? null;
	}
}