import React, { useState, useEffect } from 'react';
import { Image, Stack, PrimaryButton, ActionButton, Text } from 'office-ui-fabric-react';
import flinkerLogo from "./images/flinker-icon-black.png";
import { useLocation, useParams } from "react-router-dom";
import { Hub } from "aws-amplify";
import { Card } from '@uifabric/react-cards';
import LogoWithText from "./LogoWithText";
import { FontWeights } from '@uifabric/styling';
import Authenticator from "./Authenticator";
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';
import Strings from "./strings";
import { Overlay } from "office-ui-fabric-react";

const Unauthenticated = (props) => {

    const iconClass = mergeStyles({
        fontSize: 60,
        height: 60,
        width: 60,
        margin: '0 auto',
        paddingTop: 40,
        color: 'lightGray'
    });

    const [user, setUser] = useState(null);
    const [showSignIn, setShowSignIn] = useState(false);

    const { renderApp: App } = props;

    return (
        <>
            <Authenticator
                renderApp={() => <App />}
                onChange={(user) => setUser(user)} />
            {!user && !showSignIn &&
                <Overlay
                    styles={{ root: { background: 'white', overflowY: 'scroll' } }}>
                    <Stack
                        alignItems={'center'}
                        tokens={{ childrenGap: 10 }}
                        styles={{
                            root: {
                                padding: 20,
                            }
                        }}>
                        <Text
                            variant="xxLarge"
                            styles={{
                                root: {
                                    color: '#333333',
                                    fontWeight: FontWeights.semibold,
                                    textAlign: 'center'
                                }
                            }}>
                            {'Übersichtlich. Schnell. Bewährt.'}
                        </Text>
                        <Text
                            variant="mediumPlus"
                            styles={{
                                root: {
                                    textAlign: 'center',
                                    marginBottom: 20
                                }
                            }}>
                            {'Erstelle, bearbeite oder delegiere eine Vielzahl von Cards in wenigen Sekunden. Vereine die Vorteile moderner Cloudsysteme mit Excel.'}
                        </Text>
                        <Stack.Item align={'center'}>
                            <PrimaryButton
                                onClick={() => setShowSignIn(true)}
                                text="Loslegen"
                                styles={{
                                    root: {
                                        borderRadius: 20,
                                        marginBottom: 20
                                    }
                                }} />
                        </Stack.Item>
                        <video autoPlay muted loop>
                            <source src="./assets/excel.mp4" type="video/mp4" />
                        Your browser does not support the video tag. I suggest you upgrade your browser.
                    </video>
                        <FontIcon iconName="Cloud" className={iconClass} />
                        <Text
                            variant="medium"
                            styles={{
                                root: {
                                    fontWeight: FontWeights.semibold,
                                    textAlign: 'center',
                                    padding: 10,
                                    maxWidth: 250,
                                    margin: 'auto'
                                }
                            }}>
                            {'Nutze unsere Cloud-Schnittstelle für Microsoft Excel, um von Flinker in vertrauter Umgebung zu profitieren.'}
                        </Text>
                        <FontIcon iconName="SingleColumnEdit" className={iconClass} />
                        <Text
                            variant="medium"
                            styles={{
                                root: {
                                    fontWeight: FontWeights.semibold,
                                    textAlign: 'center',
                                    padding: 10,
                                    maxWidth: 250,
                                    margin: 'auto'
                                }
                            }}>
                            {'Mithilfe von Vorlagen lässt sich eine Excel Arbeitsmappe deinen Bedürfnissen anpassen. '}
                        </Text>
                        <FontIcon iconName="FastMode" className={iconClass} />
                        <Text
                            variant="medium"
                            styles={{
                                root: {
                                    fontWeight: FontWeights.semibold,
                                    textAlign: 'center',
                                    padding: 10,
                                    maxWidth: 250,
                                    margin: 'auto'
                                }
                            }}>
                            {'Du kannst in sekundenschnelle Cards erstellen, bearbeiten oder einem deiner Kontakte zuweisen.'}
                        </Text>
                        <Stack.Item align={'center'}>
                            <ActionButton
                                onClick={() => window.open(Strings.website)}
                                text="Mehr erfahren"
                                styles={{
                                    root: {
                                        borderRadius: 20,
                                        margin: 20
                                    }
                                }} />
                        </Stack.Item>
                    </Stack>
                </Overlay>
            }
        </>
    );
};

export default Unauthenticated
