import * as React from "react";
import {
    Label,
    Stack,
    PrimaryButton,
    ButtonType
} from "office-ui-fabric-react";
import Strings from "./strings";


function ExcelCheckAndUploadData(props) {
    const { checkInput, saveInput, canSave, loading } = props;
    return (
        <Stack style={{ margin: "20px" }} tokens={{ childrenGap: 8 }} vertical>
{/*             <Stack>
                <Label>{Strings.viewUI.sharedUi.checkAndUploadData.changesHeader}</Label>
            </Stack> */}
            <Stack tokens={{ childrenGap: 8 }} horizontal>
                <PrimaryButton
                    styles={{
                        root: {
                            borderRadius: 20
                        }
                    }}
                    grow
                    disabled={loading}
                    buttonType={ButtonType.hero}
                    onClick={checkInput}>
                    {Strings.viewUI.sharedUi.checkAndUploadData.validateButton}
                </PrimaryButton>
                <PrimaryButton
                    styles={{
                        root: {
                            borderRadius: 20
                        }
                    }}
                    grow
                    disabled={!canSave || loading}
                    buttonType={ButtonType.hero}
                    onClick={saveInput}>
                    {Strings.viewUI.sharedUi.checkAndUploadData.uploadButton}
                </PrimaryButton>
            </Stack>
        </Stack>
    );
};

export default ExcelCheckAndUploadData;