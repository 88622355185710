import * as React from "react";
import { CommandBar } from "office-ui-fabric-react/lib/CommandBar";
import { Callout } from "office-ui-fabric-react/lib/Callout";
import { CommandBarButton } from "office-ui-fabric-react/lib/Button";
import {
	getTheme,
	mergeStyleSets,
	FontWeights,
} from "office-ui-fabric-react";
import Strings from "./strings";

const theme = getTheme();
const styles = mergeStyleSets({
	header: {
		padding: "18px 24px 12px"
	},
	title: [
		theme.fonts.xLarge,
		{
			margin: 0,
			fontWeight: FontWeights.semilight
		}
	],
	inner: {
		height: "100%",
		padding: "0 24px 20px"
	},
	subtext: [
		theme.fonts.medium,
		{
			margin: 0,
			fontWeight: FontWeights.semilight
		}
	]
});

function CalloutButton(props) {
	const { onDismissCallout, isCalloutVisible, calloutHeader, calloutBody, ...buttonProps } = props;
	const targetButton = React.useRef(null);

	return (
		<>
			<div ref={targetButton}>
				<CommandBarButton {...buttonProps} />
			</div>
			{isCalloutVisible && (
				<Callout target={targetButton} onDismiss={onDismissCallout} calloutWidth={250}>
					<div className={styles.header}>
						<p className={styles.title}>{calloutHeader}</p>
					</div>
					<div className={styles.inner}>
						<p className={styles.subtext}>{calloutBody}</p>
					</div>
				</Callout>
			)}
		</>
	);
}

function _ViewCommandBar(props) {
	const {
		onRefreshClick,
		infoCalloutHeader,
		infoCalloutBody,
		onDismissCallout,
		isCalloutVisible,
		onInfoClick
	} = props;

	const nearItems = React.useMemo(() => {
		return [
			{
				key: "reload",
				text: Strings.viewUI.sharedUi.viewCommandBar.reloadButton,
				iconProps: { iconName: "Refresh" },
				onClick: onRefreshClick
			}
		];
	}, [onRefreshClick]);

	const farItems = React.useMemo(() => {
		const InfoCalloutButton = p => {
			return (
				<CalloutButton
					{...p}
					calloutHeader={infoCalloutHeader}
					calloutBody={infoCalloutBody}
					isCalloutVisible={isCalloutVisible}
					onDismissCallout={onDismissCallout}
				/>
			);
		};

		return [
			{
				key: "info",
				iconProps: { iconName: Strings.viewUI.sharedUi.viewCommandBar.infoButton },
				onClick: onInfoClick,
				commandBarButtonAs: InfoCalloutButton
			}
		];
	}, [onDismissCallout, isCalloutVisible, onInfoClick]);

	return <CommandBar style={{ padding: "0" }} items={nearItems} farItems={farItems} />;
}

function ExcelViewPaneCommandBar(props) {
	const [isCalloutVisible, setIsCalloutVisible] = React.useState(false);
	const onDismissCallout = React.useCallback(() => {
		setIsCalloutVisible(false);
	}, []);
	const onInfoClick = React.useCallback(() => {
		setIsCalloutVisible(!isCalloutVisible);
	}, [isCalloutVisible]);
	return (
		<_ViewCommandBar
			{...props}
			onDismissCallout={onDismissCallout}
			isCalloutVisible={isCalloutVisible}
			onInfoClick={onInfoClick}
		/>
	);
}

export default ExcelViewPaneCommandBar;