import { AttributeName } from "./enums";

export interface ColumnSchema {
    label: string;
    displayName: string;
    required: boolean;
    updateData: boolean;
    createData: boolean;
    options?: "STACKS" | "CONTACTS" | string[];
    type?: string;
}

export enum TableId {
    cards = "cards",
    stacks = "stacks",
    contacts = "contacts",
}