import {ColumnSchema} from "../schemata";
import DataTableRow from "./DataTableRow";
import ContactDto, {InputContact} from "../../../shared/data-services/ContactDto";

class ContactsTableRow extends DataTableRow<ContactDto> {
    constructor(sourceData: ContactDto | string[], columnSchema: ColumnSchema[]) {
        super(sourceData, columnSchema);
    };

    toInputContact(): InputContact {
        const inputContact = {};
        this.cells.forEach(cell => inputContact[cell.name] = cell.value);
        return inputContact as InputContact;
    }
}

export default ContactsTableRow;