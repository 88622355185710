import * as React from "react";
import { CommandBar } from "office-ui-fabric-react";

function ExcelCommandBar(props) {
    const {
        chooseCards,
        chooseStacks,
        chooseContacts,
        currentlySelected,
        loading
    } = props;

    const items = [
        {
            key: "cards",
            text: "Cards",
            cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
            iconProps: { iconName: "QuickNote" },
            onClick: chooseCards,
            disabled: loading,
            checked: currentlySelected === "cards",
        },
    ];

    const farItems = [
        {
            key: "stacks",
            text: "Stacks",
            ariaLabel: "Stacks",
            iconOnly: true,
            disabled: loading,
            iconProps: { iconName: "ListMirrored" },
            onClick: chooseStacks,
            checked: currentlySelected === "stacks",
        },
        {
            key: "contacts",
            text: "Mitglieder",
            ariaLabel: "Mitglieder",
            iconOnly: true,
            disabled: loading,
            iconProps: { iconName: "Contact" },
            onClick: chooseContacts,
            checked: currentlySelected === "contacts",
        },
    ];
    return <CommandBar
        items={items}
        farItems={!props.isRoot ? farItems : undefined}
    />;
}

export default ExcelCommandBar;