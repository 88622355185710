import DataTable, {CellLocation, RowData} from "./DataTable";
import Strings from "../../../shared/strings";
import {
    infoCellColorFromRowState,
    infoCellDisplayStringFromRowState,
    infoCellTextColorFromRowState,
} from "../mappings";
import Colors from "../colors";
import { DataTableRowState } from "../enums";

interface RangeFormatting {
    border?: {
        style?: any;
        color?: string;
    };
    fillColor?: string;
    fontStyle?: {
        bold?: boolean;
        color?: string;
        underline?: any;
    };
}

const NUM_CHARS_TO_WRAP = 50;
const COL_WRAP_WIDTH = 200;
const DEFAULT_CELL_WIDTH = 64;
const DEFAULT_CELL_HEIGHT = 15;


export class ExcelInteractor {
    private readonly defaultStyledBorderIndices: Excel.BorderIndex[] = [
        Excel.BorderIndex.edgeBottom,
        Excel.BorderIndex.edgeLeft,
        Excel.BorderIndex.edgeRight,
        Excel.BorderIndex.edgeTop,
        Excel.BorderIndex.insideHorizontal,
    ];
    private cellCharWidthsCache: number[][] = [[]];
    private activeExcelTableId: string;
    private readonly workingWorksheet: Excel.Worksheet;
    private currentDataTable: DataTable;
    constructor(workingWorksheet: Excel.Worksheet, initialDataTable: DataTable) {
        this.activeExcelTableId = "";
        this.currentDataTable = initialDataTable;
        this.workingWorksheet = workingWorksheet;
        this.init();
    }


    async init() {
        await Excel.run(this.workingWorksheet, async (context) => {
            this.workingWorksheet.getRange().format.rowHeight = 15;
            this.workingWorksheet.getRange("A1:A1").format.rowHeight = 15;
            await context.sync();
        }).catch((e) => console.log(e));
    }

    async renderDataTable(DataTable: DataTable, onTableEdit: () => Promise<any>) {
        await Excel.run(this.workingWorksheet, async (context) => {

            this.currentDataTable = DataTable;
            this.clearSheet(); // 10%
            const excelTable = await this.newExcelTableFromView(onTableEdit);
            excelTable.load("id");
            await context.sync();
            context.application.suspendScreenUpdatingUntilNextSync();
            context.application.suspendApiCalculationUntilNextSync();
            this.activeExcelTableId = excelTable.id;
            this.updateExcelTableWithRowRange(); // 40%
            context.application.suspendApiCalculationUntilNextSync();
            this.formatTable(); // 60%
        }).catch((e) => console.log(e));
    }

    async validateDataTable(DataTable: DataTable, onTableEdit: () => Promise<any>) {
        await Excel.run(this.workingWorksheet, async (context) => {

            this.currentDataTable = DataTable;
            await context.sync();
            context.application.suspendScreenUpdatingUntilNextSync();
            context.application.suspendApiCalculationUntilNextSync();
            this.updateExcelTableWithRowRange(); // 40%
            this.formatTable(); // 60%

        }).catch((e) => console.log(e));
    }


    async getRawRowDataWithIds(): Promise<RowData[]> {
        const rawRowDataWithIds: RowData[] = [];
        const uniqueColIndex = this.currentDataTable.getUniqueColumnIndex();
        await Excel.run(this.workingWorksheet, async (context) => {
            const tableDataRange = this.getActiveExcelTable().getDataBodyRange().load("text");
            const tableCellProps = tableDataRange.getCellProperties({hyperlink: true});
            await context.sync();
            rawRowDataWithIds.push(...tableDataRange.text.map((rawTextValues, rowIndex) => {
                const rawCellEntries = rawTextValues.slice(0, rawTextValues.length - 1);
                const markedForDeletion = rawTextValues[rawTextValues.length - 1] !== "";
                const id = tableCellProps.value[rowIndex][uniqueColIndex].hyperlink?.address;
                return {
                    id: id ? this.idFromAddress(id) : undefined,
                    rawCellData: this.sanitizeOutput(rawCellEntries),
                    markedForDeletion: markedForDeletion,
                };
            }));
        }).catch((e) => console.log(e));
        return rawRowDataWithIds;
    }

    async renderRowRangeFromTable(DataTable: DataTable, rowStart: number = 0, rowEnd?: number) {
        await Excel.run(this.workingWorksheet, async (ctx) => {
            if (this.currentDataTable.getName() !== DataTable.getName()) {
                throw new Error("Cannot add rows from a different table!");
            } else {
                this.updateExcelTableWithRowRange(rowStart, rowEnd);
                this.formatTable();
                await ctx.sync();
            }
        }).catch((e) => console.log(e));
    }

    private updateExcelTableWithRowRange(rowStart: number = 0, rowEnd?: number) {
        rowEnd = rowEnd ?? this.currentDataTable.getRowCount();
        const newRows = this.currentDataTable.getRowData().slice(rowStart, rowEnd);
        this.appendRawRowsToExcelTable(newRows); // 20%
         for (let rowIndex = rowStart; rowIndex < rowEnd; rowIndex++) {
            const idColumn = this.currentDataTable.getUniqueColumnIndex();
                if (newRows[rowIndex].id) {
                // 50%
                this.addIdLinkToCell(
                    {row: rowIndex, col: idColumn},
                    newRows[rowIndex].id!,
                );
            }
            this.updateInfoCellStringForRow(rowIndex); //50%
        } 
    }

    private appendRawRowsToExcelTable(rawRows: RowData[]) {
        if (rawRows.length > 0) {
            const excelTable = this.getActiveExcelTable();
            const rowsEscapedWithDeleteColumn = rawRows.map((newRow) => [
                ...this.addEscapeApostropheToCells(newRow.rawCellData),
                newRow.markedForDeletion ? Strings.tables.deleteOption : "",
            ]);
            console.log(rowsEscapedWithDeleteColumn);
            const rangeToWriteTo = excelTable.getDataBodyRange()
            .getCell(this.tableOriginInExcel().row,this.tableOriginInExcel().col-1).untrack()
            .getResizedRange(this.currentDataTable.getRowCount()-1,this.currentDataTable.getColumnCount()).untrack();
            rangeToWriteTo.values = rowsEscapedWithDeleteColumn;
        }
    }

    private setupTableHeader(): void {
        const infoHeaderRange = this.workingWorksheet.getCell(
            this.currentDataTable.getStartCellLocation().row,
            this.currentDataTable.getStartCellLocation().col,
        ).untrack();
        infoHeaderRange.values = [[Strings.tables.infoHeader]];
        const headerRangeForDeleteColumn = this.rangeForDeleteCell(-1).untrack();
        headerRangeForDeleteColumn.values = [[Strings.tables.deleteOptionHeader]];
        this.excelTableHeaderRangeForCurrentDataTable().values = [
            this.currentDataTable.getColumnNames(),
        ];
    }


    private getActiveExcelTable() {
        return this.workingWorksheet.tables.getItem(this.activeExcelTableId);
    }

    private updateInfoCellStringForRow(rowIndex: number) {
        let text;
        if (this.currentDataTable.rowMarkedForDeletion(rowIndex)) {
            text = Strings.tables.infoCellStatus.toDelete;
            this.infoCellRange(rowIndex).values = [[text]];
        } else if(this.currentDataTable.getRowState(rowIndex) != DataTableRowState.UNCHANGED) {
            text = infoCellDisplayStringFromRowState[this.currentDataTable.getRowState(rowIndex)];
            this.infoCellRange(rowIndex).values = [[text]];
        }
    }

    private formatTable() {
        this.cellCharWidthsCache = this.currentDataTable
            .getRowData()
            .map((rowData) => rowData.rawCellData.map((rawCellData) => rawCellData.length));
        this.formatColumns(); // 20%
        this.formatRows(); // 80%
    }

    private formatRows(rowStart: number = 0, rowEnd?: number) {
        rowEnd = rowEnd ?? this.currentDataTable.getRowCount();
        for (let i = rowStart; i < rowEnd; i++) {
            this.formatInfoCellForRow(i);
            this.formatCellsInRow(i);
            //this.formatDeleteCellForRow(i);
            const rowNeedsDoubleHeight = this.cellCharWidthsCache[i].some(
                (cellCharCount) => cellCharCount > NUM_CHARS_TO_WRAP,
            );
            if (rowNeedsDoubleHeight) this.rowRange(i).format.rowHeight = 32;
        }
    }

    private formatDeleteCellForRow(rowIndex: number) {
        if (this.currentDataTable.rowMarkedForDeletion(rowIndex)) {
            this.styleRange(this.rangeForDeleteCell(rowIndex), {
                fillColor: Colors.cell.activeDeleteCell,
                fontStyle: {
                    bold: true,
                    color: Colors.cellText.activeDeleteCell,
                },
            });
        }
    }

    private formatInfoCellForRow(rowIndex: number) {
        if (this.currentDataTable.getRowState(rowIndex) != DataTableRowState.UNCHANGED) {
            this.styleRange(this.infoCellRange(rowIndex), {
                fontStyle: {
                    color: infoCellTextColorFromRowState[this.currentDataTable.getRowState(rowIndex)],
                },
                fillColor: infoCellColorFromRowState[this.currentDataTable.getRowState(rowIndex)],
            });
        }
        if (this.currentDataTable.rowIsDuplicate(rowIndex)) {
            this.infoCellRange(rowIndex).dataValidation.prompt = {
                message: Strings.tables.errors.rowIsDupe,
                showPrompt: true,
                title: Strings.tables.errors.rowGeneric,
            };
        }
    }

    private formatCellsInRow(rowIndex: number) {
        for (let i = 0; i < this.currentDataTable.getColumnCount(); i++) {
            const cellLocation = {col: i, row: rowIndex};
            const validationMessage = this.currentDataTable.getValidationMessageAtLocation(
                cellLocation,
            );
            if (validationMessage !== "") {
                this.formatErrorCell(cellLocation, validationMessage);
            } else if (this.currentDataTable.attributeUpdatedAtLocation(cellLocation)) {
                this.formatModifiedCell(cellLocation);
            }
        }
    }

    private formatModifiedCell(cellLocation: CellLocation) {
        this.styleRange(this.cellRangeForLocation(cellLocation), {
            fillColor: Colors.cell.edited,
            fontStyle: {color: Colors.cellText.edited},
        });
    }

    private formatErrorCell(cellLocation: CellLocation, validationMessage: string) {
        this.styleRange(this.cellRangeForLocation(cellLocation), {fillColor: Colors.cell.error});
        this.cellRangeForLocation(cellLocation).dataValidation.prompt = {
            message: validationMessage,
            showPrompt: true,
            title: Strings.tables.errors.cellGeneric,
        };
    }

    private cellRangeForLocation(cellLocation: CellLocation) {
        const range = this.workingWorksheet.getCell(
            this.tableOriginInExcel().row + cellLocation.row + 1, // + 1 for header
            this.tableOriginInExcel().col + cellLocation.col,
        ).untrack();
        return range;
    }

    private addIdLinkToCell(cellLocation: CellLocation, id: string) {
        const cellValue = this.currentDataTable.rawCellValueAt(cellLocation);
        this.cellRangeForLocation(cellLocation).hyperlink = {
            address: Strings.host + id,
            textToDisplay: "'"+cellValue,
        };
    }

    private formatColumns() {
        this.addDropdownsForColumns();
        if (Office.context.requirements.isSetSupported("ExcelApi", "1.2")) {
            this.workingWorksheet.getUsedRange().format.autofitColumns();
        }
        this.wrapColumnsOverCharLimit();
        this.styleColumns();
    }

    private addDropdownsForColumns() {
        for (let i = 0; i < this.currentDataTable.getColumnCount(); i++) {
            const columnDropdownList = this.getDropdownsForColumn(i);
            if (columnDropdownList) {
                this.columnRangeInExcelTable(i).dataValidation.rule = {
                    list: {inCellDropDown: true, source: columnDropdownList},
                };
            }
        }
        this.rangeForDeleteColumn().dataValidation.rule = {
            list: {inCellDropDown: true, source: `, ${Strings.tables.deleteOption}`},
        };
    }

    private columnRangeInExcelTable(colIndex: number): Excel.Range {
        if (colIndex < 0) {
            colIndex += this.currentDataTable.getColumnCount() + 1;
        }
        const excelAdjustedRowCount = Math.max(1, this.currentDataTable.getRowCount());
        return this.workingWorksheet.getRangeByIndexes(
            this.currentDataTable.getStartCellLocation().row + 1,
            this.currentDataTable.getStartCellLocation().col + colIndex + 1, // + 1 for info cell
            this.currentDataTable.getStartCellLocation().row + excelAdjustedRowCount,
            1,
        );
    }

    private rangeForDeleteColumn() {
        return this.columnRangeInExcelTable(-1);
    }

    private rangeForDeleteCell(rowIndex: number): Excel.Range {
        return this.workingWorksheet.getCell(
            this.currentDataTable.getStartCellLocation().row + 1 + rowIndex,
            this.currentDataTable.getStartCellLocation().col +
                this.currentDataTable.getColumnCount() +
                1,
        );
    }

    private infoCellRange(rowIndex: number): Excel.Range {
        return this.workingWorksheet.getRangeByIndexes(
            this.currentDataTable.getStartCellLocation().row + rowIndex + 1,
            this.currentDataTable.getStartCellLocation().col,
            1,
            1,
        ).untrack();
    }

    private async newExcelTableFromView(onEditCallback: () => Promise<any>): Promise<Excel.Table> {
        const excelTable = this.workingWorksheet.tables.add(
            this.excelTableHeaderRangeForCurrentDataTable(),
            true,
        );
        excelTable.name = this.currentDataTable.getName();
        this.assignTableFormatting(this.currentDataTable.getFormattingRules(), excelTable);
        this.setupTableHeader();
        excelTable.onChanged.add(onEditCallback);
        return excelTable;
    }

    private assignTableFormatting(formattingRules: any, excelTable: Excel.Table) {
        for (const formattingRule in formattingRules) {
            excelTable[formattingRule] = formattingRules[formattingRule];
        }
    }

    
    async clearActiveSheet(): Promise<void> {
        await Excel.run(this.workingWorksheet, async (context) => {
            this.workingWorksheet.getRange().clear();
            this.workingWorksheet.getRange().convertDataTypeToText();
            return context.sync();
        });
    }

    private clearSheet(): void {
        var range = this.workingWorksheet.getRange().untrack()
        range.clear();
        range.format.verticalAlignment="Top"
        range.format.columnWidth = DEFAULT_CELL_WIDTH;
        range.format.rowHeight = DEFAULT_CELL_HEIGHT; 
    }

    private excelTableHeaderRangeForCurrentDataTable() {
        return this.workingWorksheet.getRangeByIndexes(
            this.tableOriginInExcel().row,
            this.tableOriginInExcel().col,
            1,
            this.currentDataTable.getColumnCount(),
        ).untrack();
    }

    private styleRange(range: Excel.Range, rangeFormatting: RangeFormatting): void {
        range.format.fill.color = rangeFormatting.fillColor ?? Colors.cell.default;
        range.format.font.color = rangeFormatting.fontStyle?.color ?? Colors.cellText.default;
        range.format.font.bold = rangeFormatting.fontStyle?.bold ?? false;
        range.format.font.underline = rangeFormatting.fontStyle?.underline ?? "None";
        if (rangeFormatting.border) {
            for (const borderIndex of this.defaultStyledBorderIndices) {
                this.borderAt(range, borderIndex).color =
                    rangeFormatting.border.color ?? Colors.cellBorder.default;
                this.borderAt(range, borderIndex).style =
                    rangeFormatting.border.style ?? Excel.BorderLineStyle.continuous;
            }
        }
    }

    private borderAt(range: Excel.Range, index: string) {
        return range.format.borders.getItem(index as Excel.BorderIndex);
    }

    private getDropdownsForColumn(colIndex: number): string | null {
        const columnDropdowns = this.currentDataTable.getDropdownOptions(colIndex);
        if (columnDropdowns.length <= 0) {
            return null;
        } else {
            return columnDropdowns.reduce(
                (prev, val) => prev + this.escapeDropdownComma(val) + ",",
                "",
            );
        }
    }

    private formattingPropertiesForColumn(colIndex: number): RangeFormatting {
        const formatting: RangeFormatting = {};
        if (this.currentDataTable.getUniqueColumnIndex() === colIndex) {
            formatting.fontStyle = { color: Colors.cellText.hyperlink, underline: Excel.RangeUnderlineStyle.single };
        }
        else if (this.currentDataTable.columnIsCreatable(colIndex)) {
            formatting.fillColor = Colors.column.creatable;
            formatting.border = { color: Colors.column.border.creatable, style: Excel.BorderLineStyle.continuous };
        }
        else {
            formatting.fillColor = Colors.column.readonly;
            formatting.border = { color: Colors.column.border.readonly, style: Excel.BorderLineStyle.continuous };
        }
        return formatting;
    }

    private wrapColumnsOverCharLimit(): void {
        for (let i = 0; i < this.currentDataTable.getColumnCount(); i++) {
            const widestCellInColumn = Math.max(...this.cellCharWidthsCache.map((row) => row[i]));
            if (widestCellInColumn > NUM_CHARS_TO_WRAP) {
                Object.assign(this.columnRangeInExcelTable(i).format, {
                    columnWidth: COL_WRAP_WIDTH,
                    wrapText: true,
                });
            }
        }
    }

    private styleColumns(): void {
        this.styleRange(this.infoCellHeaderRange(), {
            fillColor: Colors.columnHeader.status,
            border: {color: Colors.column.border.status, style: Excel.BorderLineStyle.continuous},
            fontStyle: {bold: true, color: Colors.columnHeaderText.status},
        });
        this.styleRange(this.infoCellColumnRange(), {
            fillColor: Colors.column.status,
            border: {color: Colors.column.border.status, style: Excel.BorderLineStyle.continuous},
            fontStyle: {color: Colors.cellText.status}
        });
        for (let i = 0; i < this.currentDataTable.getColumnCount(); i++) {
            this.styleRange(this.columnRangeInExcelTable(i), this.formattingPropertiesForColumn(i));
        }
        this.styleRange(this.rangeForDeleteColumn(), {
            fillColor: Colors.column.delete,
            border: {color: Colors.column.border.delete, style: Excel.BorderLineStyle.continuous},
        });
    }

    private rowRange(rowIndex: number) {
        const columnCountWithInfoAndDeleteCols = this.currentDataTable.getColumnCount() + 2;
        return this.workingWorksheet.getRangeByIndexes(
            rowIndex + 1,
            0,
            1,
            columnCountWithInfoAndDeleteCols,
        ).untrack();
    }

    private infoCellColumnRange(): Excel.Range {
        return this.columnRangeInExcelTable(-1);
    }

    private infoCellHeaderRange(): Excel.Range {
        return this.infoCellRange(-1);
    }

    private tableOriginInExcel(): CellLocation {
        return {
            col: this.currentDataTable.getStartCellLocation().col + 1, // + 1 for info cell
            row: this.currentDataTable.getStartCellLocation().row,
        };
    }

    private placeCursorAtEndOfTable(): void {
        this.columnRangeInExcelTable(0).getLastCell().getCell(1, 0).select();
    }

    private sanitizeOutput(outputVals: string[]): string[] {
        return outputVals.map((val, colIndex) => {
            if (this.currentDataTable.getDropdownOptions(colIndex)) {
                return this.undoDropdownCommaEscape(val);
            } else {
                return val;
            }
        });
    }

    private idFromAddress(address: string) {
        return address.slice(address.lastIndexOf("/") + 1);
    }

    private addEscapeApostropheToCells(cellData: string[]) {
        return cellData.map((cellData, colIndex) => {
            const colType = this.currentDataTable.getColumnType(colIndex);
            const hasCustomFormat = (colType == "Number");
            return (!hasCustomFormat ? "'" : "") + cellData;
        });
    }

    private undoDropdownCommaEscape(val): string {
        return val.replace("&COMMA&", ",");
    }

    private escapeDropdownComma(val: string): string {
        return val.replace(",", "&COMMA&");
    }
}

let InteractorSingleton: ExcelInteractor;

const getActiveWorksheet: () => Promise<Excel.Worksheet> = async () => {
    return Excel.run(async (context) => {
        const activeWorksheet = context.workbook.worksheets.getActiveWorksheet();
        await context.sync();
        return activeWorksheet;
    });
};

const getWorksheet: () => Promise<Excel.Worksheet> = async () => {
    return Excel.run(async (context) => {
        var sheets = context.workbook.worksheets;
        sheets.load("items/name");
        await context.sync();
        const sheetExists = sheets.items.some(s => s.name == 'Flinker');
        if (!sheetExists){
            const sheet = sheets.add("Flinker");
            sheet.activate();
            return sheet;
        } else {
            const sheet = sheets.getItem("Flinker");
            sheet.activate();
            return sheet;
        }
    });
};

const requestInteractor: (et: DataTable) => Promise<ExcelInteractor> = async (initialDataTable) => {
    if (InteractorSingleton !== undefined) {
        return InteractorSingleton;
    } else {
        await Office.onReady();
        const activeWorksheet = await getWorksheet();
        InteractorSingleton = new ExcelInteractor(activeWorksheet, initialDataTable);
        return InteractorSingleton;
    }
};

export default requestInteractor;
