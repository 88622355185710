// List of colors used
const colors = {
    cell: {
        status: "#ffffff",
        delete: "#ffffff",
        new: "#dbffd8",
        error: "#ffdfe2",
        edited: "#fff4cd",
        default: "#ffffff",
        hyperlink: "#ffffff",
        activeDeleteCell: "#bf0000"
    },
    cellText: {
        status: "#000000",
        delete: "#000000",
        new: "#1d3e1c",
        error: "#421e1d",
        edited: "#4c3e19",
        default: "#000000",
        hyperlink: "#054bd4",
        activeDeleteCell: "#ffffff",
    },
    cellBorder: {
        activeDeleteCell: "#000000",
        new: "#000000",
        error: "#000000",
        edited: "#000000",
        default: "#000000",
    },
    status: {
        new: "#c6fdc1",
        error: "#ffb4ab",
        edited: "#ffe1ac",
        default: "#ffffff",
    },
    statusText: {
        new: "#1d3e1c",
        error: "#421e1d",
        edited: "#4c3e19",
        default: "#000000",
    },
    columnHeader: {
        delete: "#ffffff",
        status: "#ffffff",
        readonly: "#ffffff",
        creatable: "#ffffff",
        border: {
            delete: "#000000",
            status: "#000000",
            readonly: "#000000",
            creatable: "#000000",
        }
    },
    columnHeaderText: {
        delete: "#000000",
        status: "#000000",
        readonly: "#000000",
        creatable: "#000000",
        border: {
            delete: "#000000",
            status: "#000000",
            readonly: "#000000",
            creatable: "#000000",
        }
    },
    column: {
        delete: "#ffffff",
        status: "#ffffff",
        readonly: "#ffffff",
        creatable: "#ffffff",
        border: {
            delete: "#cccccc",
            status: "#aaaaaa",
            readonly: "#cccccc",
            creatable: "#aaaaaa",
        }
    },
    app: {
        primary: "#009900",
    }
}

export default colors;