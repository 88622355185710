import * as React from "react";
import {
    ButtonType,
    ActionButton,
    Stack,
    Dropdown,
    DropdownMenuItemType,
    Label, Spinner, Toggle
} from "office-ui-fabric-react";
import CommandBar from "./ExcelViewPaneCommandBar";
import moment from "moment";
import Strings from "./strings";
import "../excel-addin/src/utils";
import { DataServices as Ds } from "./data-services/DataServices";
import formatUnicorn from "format-unicorn/safe";


const dropdownStyle = {
    title: {
        border: 'none',
        paddingLeft: 5
    },
    dropdown: {
        width: 200
    }
}

class ExcelPaneCards extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true,
            timeAhead: null,
            endAt: null,
            selectedDateKey: null,
            startAt: null,
            stackId: null,
            stacks: [],
            contacts: [],
            closed: false
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.activityId !== prevProps.activityId) {
            this.fetchData();
        }
    }

    async fetchData() {
        this.setState({ loading: true }, async () => {
            this.setState({
                stacks: await Ds.StacksService.getStacksForActivity(this.props.activityId),
                contacts: await Ds.ContactsService.getContactsForActivity(this.props.activityId),
                loading: false,
            });
        })
    }

    loadMoreCards() {
        this.props.loadMoreItems();
    }

    onStackChange(event, item) {
        this.setState({ stackId: item.key },
            this.updateFilter
        );
    }

    onContactChange(event, item) {
        this.setState({ contactId: item.key },
            this.updateFilter
        );
    }

    updateFilter() {
        this.props.updateFilter({
            stackId: this.state.stackId,
            contactId: this.state.contactId,
            startAt: this.state.startAt,
            endAt: this.state.endAt,
            closed: this.state.closed,
            date: this.state.selectedDateKey
        });
    }

    onClosedChange = async (ev, checked) => {
        this.setState({
            closed: checked,
        },
            this.updateFilter
        );
    }

    onEventsChange = async (ev, checked) => {
        this.setState({
            endAt: checked ? "2100-01-01T00:00:00.000Z" : null,
        },
            this.updateFilter
        );
    }

    

    onDateChange = async (event, item) => {
        let startAt = null;
        let endAt = null;
        let timeAhead = null;

        const startOfDay = moment().startOf("day");
        const startOfWeek = moment().startOf("week");
        const startOfMonth = moment().startOf("month");
        const startOfYear = moment().startOf("year");
        const hourMs = 60 * 60 * 1000;
        const dayMs = hourMs * 24;
        const weekMs = dayMs * 7;

        switch (item.key) {
            case "last hour":
                startAt = moment().toISOString();
                endAt = moment()
                    .clone()
                    .add(1, "hour")
                    .toISOString();
                break;
            case "tomorrow":
                startAt = startOfDay
                    .clone()
                    .add(1, "day")
                    .toISOString();
                endAt = startOfDay
                    .clone()
                    .add(2, "day")
                    .toISOString();
                break;
            case "today":
                startAt = startOfDay
                    .clone()
                    .toISOString();
                endAt = startOfDay
                    .clone()
                    .add(1, "day")
                    .toISOString();
                break;
            case "yesterday":
                startAt = startOfDay
                    .clone()
                    .subtract(1, "day")
                    .toISOString();
                endAt = startOfDay
                    .clone()
                    .toISOString();
                break;
            case "next week":
                startAt = startOfWeek
                    .clone()
                    .add(1, "week")
                    .toISOString();
                endAt = startOfWeek
                    .clone()
                    .add(2, "week")
                    .toISOString();
                break;
            case "this week":
                startAt = startOfWeek
                    .clone()
                    .toISOString();
                endAt = startOfWeek
                    .clone()
                    .add(1, "week")
                    .toISOString();
                break;
            case "last week":
                startAt = startOfWeek
                    .clone()
                    .subtract(1, "week")
                    .toISOString();
                endAt = startOfWeek
                    .clone()
                    .toISOString();
                break;
            case "next month":
                startAt = startOfWeek
                    .clone()
                    .add(1, "month")
                    .toISOString();
                endAt = startOfMonth
                    .clone()
                    .add(2, "month")
                    .toISOString();
                break;
            case "this month":
                startAt = startOfMonth
                    .clone()
                    .toISOString();
                endAt = startOfMonth
                    .clone()
                    .add(1, "month")
                    .toISOString();
                break;
            case "last month":
                startAt = startOfMonth
                    .clone()
                    .subtract(1, "month")
                    .toISOString();
                endAt = startOfMonth
                    .clone()
                    .toISOString();
                break;
        }
        this.setState({
            selectedDateKey: item.key,
            startAt,
            endAt,
        },
            this.updateFilter
        );
    };

    render() {
        const {
            loadedItemCount,
            allLoaded,
            visible,
            activeFilter,
            activityId,
        } = this.props;

        if (!visible) {
            return null;
        }

        const stackOptions = [{ key: null, text: Strings.viewUI.cards.stackFilterAllStacks }];
        stackOptions.push(...this.state.stacks.map(s => ({ key: s.id, text: s.name })));

        const contactOptions = [{ key: null, text: Strings.viewUI.cards.contactFilterAllContacts }];
        contactOptions.push(...this.state.contacts.map(c => ({ key: c.id, text: c.email })));

        const dateOptions = [
            { key: "", text: Strings.viewUI.cards.dateFilters.noOption },
            { key: "divider_1", text: "-", itemType: DropdownMenuItemType.Divider },
            { key: "last hour", text: Strings.viewUI.cards.dateFilters.lastHour },
            { key: "divider_2", text: "-", itemType: DropdownMenuItemType.Divider },
            { key: "tomorrow", text: Strings.viewUI.cards.dateFilters.tomorrow },
            { key: "today", text: Strings.viewUI.cards.dateFilters.today },
            { key: "yesterday", text: Strings.viewUI.cards.dateFilters.yesterday },
            { key: "divider_3", text: "-", itemType: DropdownMenuItemType.Divider },
            { key: "next week", text: Strings.viewUI.cards.dateFilters.nextWeek },
            { key: "this week", text: Strings.viewUI.cards.dateFilters.thisWeek },
            { key: "last week", text: Strings.viewUI.cards.dateFilters.lastWeek },
            { key: "divider_4", text: "-", itemType: DropdownMenuItemType.Divider },
            { key: "next month", text: Strings.viewUI.cards.dateFilters.nextMonth },
            { key: "this month", text: Strings.viewUI.cards.dateFilters.thisMonth },
            { key: "last month", text: Strings.viewUI.cards.dateFilters.lastMonth },
/*             { key: "divider_5", text: "-", itemType: DropdownMenuItemType.Divider },
            { key: "next year", text: Strings.viewUI.cards.dateFilters.nextYear },
            { key: "this year", text: Strings.viewUI.cards.dateFilters.thisYear },
            { key: "last year", text: Strings.viewUI.cards.dateFilters.lastYear } */
        ];

        return (
            <div className="ms-welcome">
                {this.state.loading ?
                    <Spinner /> : (
                        <Stack style={{ margin: "20px" }} tokens={{ childrenGap: 8 }}>
                            <Label styles={{ root: { paddingLeft: 5 } }}>
                                {formatUnicorn(Strings.viewUI.cards.cardCount, [loadedItemCount])}
                            </Label>
                            {/*                     <ActionButton
                        disabled={!allLoaded}
                        buttonType={ButtonType.hero}
                        onClick={() => this.loadMoreCards()}
                        iconProps={{ iconName: "CloudDownload", style: { fontSize: 20 } }}
                    >
                        {allLoaded ?
                            Strings.viewUI.cards.loadMoreCards :
                            Strings.viewUI.cards.allCardsLoaded}
                    </ActionButton> */}
                            <Toggle
                                label="Erledigtes anzeigen"
                                styles={{ label: { fontWeight: 'normal', paddingLeft: 5 } }}
                                inlineLabel
                                disabled={this.props.loading}
                                onChange={(event, checked) => this.onClosedChange(event, checked)} />
                            <Toggle
                                label="Termine anzeigen"
                                styles={{ label: { fontWeight: 'normal', paddingLeft: 5 } }}
                                inlineLabel
                                disabled={this.props.loading}
                                onChange={(event, checked) => this.onEventsChange(event, checked)} />
                            <Stack horizontal horizontalAlign={"space-between"}>
                                <Dropdown
                                    placeholder={Strings.viewUI.cards.dateFilterPlaceholder}
                                    options={dateOptions}
                                    disabled={this.props.loading}
                                    onChange={(event, item) => this.onDateChange(event, item)}
                                    styles={dropdownStyle}
                                    selectedKey={activeFilter?.date ?? null}
                                />
                            </Stack>
                            {!this.props.isRoot &&
                                <Stack horizontal horizontalAlign={"space-between"}>
                                    <Dropdown
                                        placeholder={Strings.viewUI.cards.stackFilterPlaceholder}
                                        options={stackOptions}
                                        disabled={this.props.loading}
                                        styles={dropdownStyle}
                                        onChange={(event, item) => this.onStackChange(event, item)}
                                        selectedKey={activeFilter?.stackId ?? null}
                                    />
                                </Stack>}
                            {!this.props.isRoot &&
                                <Stack horizontal horizontalAlign={"space-between"}>
                                    <Dropdown
                                        placeholder={Strings.viewUI.cards.contactFilterPlaceholder}
                                        options={contactOptions}
                                        disabled={this.props.loading}
                                        styles={dropdownStyle}
                                        onChange={(event, item) => this.onContactChange(event, item)}
                                        selectedKey={activeFilter?.contactId ?? null}
                                    />
                                </Stack>
                            }
                        </Stack>
                    )}
            </div>
        );
    }
}

export default ExcelPaneCards;
