import * as React from "react";
import { MessageBar, MessageBarType } from "office-ui-fabric-react";
import Strings from "./strings";
import "../excel-addin/src/utils";
import formatUnicorn from "format-unicorn/safe";

const messageMapping = {
	"createStack": Strings.viewUI.messages.entryCreateSuccess,
	"createCard": Strings.viewUI.messages.entryCreateSuccess,
	"createContact": Strings.viewUI.messages.entryCreateSuccess,
	"updateStack": Strings.viewUI.messages.entryUpdateSuccess,
	"updateCard": Strings.viewUI.messages.entryUpdateSuccess,
	"updateCardDate": Strings.viewUI.messages.entryUpdateSuccess,
	"updateContact": Strings.viewUI.messages.entryUpdateSuccess,
	"changeCardStack": Strings.viewUI.messages.entryUpdateSuccess,
	"deactivateCard": Strings.viewUI.messages.entryDeleteSuccess,
	"openCard": Strings.viewUI.messages.entryUpdateSuccess,
	"closeCard": Strings.viewUI.messages.entryUpdateSuccess,
};

const ExcelDbMessages = ({ messageItems }) => {
	return messageItems?.map((item, itemIndex) => {
		const isErrorMessage = item.errors && item.errors.length > 0;
		let id = itemIndex;
		let mainMessage = "";
		if (!isErrorMessage) {
			const type = Object.keys(item.data)[0];
			const counterId = item.data[type].counterId;
			const name = item.data[type].name;
			if (Object.keys(messageMapping).includes(type)) {
				mainMessage = formatUnicorn(messageMapping[type], counterId ? "#" + counterId : (name ?? ""));
			}
		} else {
			mainMessage = item.errors[0].message;
			if (mainMessage.startsWith('Not Authorized to access createCard'))
				mainMessage = Strings.viewUI.messages.noProError;
		}
		return (
			<MessageBar
				key={id}
				messageBarType={isErrorMessage ? MessageBarType.error : MessageBarType.success}
				isMultiline={false}
				truncated={isErrorMessage}
				overflowButtonAriaLabel="See more"
				dismissButtonAriaLabel="Close"
				styles={{root: {background: "white"}}}
			>
				{isErrorMessage && <><b>{Strings.viewUI.messages.entryError}</b><br /></>}
				{mainMessage}
			</MessageBar>
		);
	}) ?? null;
};

export default ExcelDbMessages;
