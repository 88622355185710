import {API, graphqlOperation} from "aws-amplify";
import {
    getActivityRoot,
    listActivities,
    listTeamActivities,
    createActivity,
    createActivityTeam,
    businessCreateActivity,
    updateActivityToPremium,
    updateActivityToFree
} from "./graphql";
import {GraphQLResult} from "@aws-amplify/api-graphql";
import ActivityDto, {ActivityServerResponseDto, UploadActivity} from "./ActivityDto";
import RootActivityDto from "./RootActivityDto";

class ActivitiesService {
    private readonly activitiesCache: Record<string, ActivityDto> = {};
    private rootActivity?: RootActivityDto;
    private activeActivityId: string | null = null;
    constructor() {}

    getActiveActivityId(): string {
        if (!this.activeActivityId) {
            throw new Error(`Function 'getActiveActivityId' cannot be called until an active activityId has been
             set in the ActivitiesService`);
        }
        return this.activeActivityId;
    }

    setActiveActivityId(newActivityId) {
        this.activeActivityId = newActivityId;
    }

    getActiveActivity(): ActivityDto | null {
        if (this.activeActivityId) {
            return this.activitiesCache[this.activeActivityId] ?? null;
        }
        else {
            return null;
        }
    }

    async getRootActivity(): Promise<RootActivityDto | null> {
        if (!this.rootActivity) {
            await this.loadRootActivity();
        }
        return this.rootActivity ?? null;
    }

    async getActivityById(activityId: string): Promise<ActivityDto> {
        if (!this.activitiesCache[activityId]) {
            await this.loadAllActivities();
        }
        return this.activitiesCache[activityId];
    }

    async getAllActivities(): Promise<ActivityDto[]> {
        if (Object.values(this.activitiesCache).length === 0) {
            await this.loadAllActivities();
        }
        return Object.values(this.activitiesCache);
    }

    async createActivity(newActivity: UploadActivity) {
        const activity: any = {...newActivity};
        return API.graphql(graphqlOperation(createActivity, {
            name: activity.name,
        }));
    }

    private async loadRootActivity() {
        const result = (await API.graphql(graphqlOperation(getActivityRoot))) as GraphQLResult<any>;
        if (result.data.getActivityRoot) {
            this.rootActivity = result.data.getActivityRoot;
            if (!this.activeActivityId) {
                this.activeActivityId = result.data.getActivityRoot.id;
            }
        }
    }

    private async loadAllActivities() {
        if (!this.rootActivity) {
            throw new Error("No activities can be loaded before the root activity has first been loaded.");
        }
        try {
            const result = (await API.graphql(graphqlOperation(listActivities))) as GraphQLResult<any>;
            if (result.data.listActivities) {
                result.data.listActivities.items.sort((a1, a2) => {
                    if (a1.type == "ROOT") return -1;
                    if (a2.type == "ROOT") return 1;
                    return a2.updatedAt.localeCompare(a1.updatedAt);
                });
                for (const activity of result.data.listActivities.items as ActivityServerResponseDto[]) {
                    this.activitiesCache[activity.id] = this.serverDataToInternalDto(activity);
                }
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    private serverDataToInternalDto(activityResponseObject: ActivityServerResponseDto): ActivityDto {
        const activity: any = {...activityResponseObject};
        if (activityResponseObject.createdBy === this.rootActivity!.createdBy) {
            activity.isMine = true;
        }
        if (activityResponseObject.appendix) {
            const parsedAppendix = JSON.parse(activityResponseObject.appendix)
            activity.appendix = parsedAppendix;
        }
        return activity as ActivityDto;
    }
}

export default ActivitiesService;