import * as React from "react";
import CommandBar from "./ExcelViewPaneCommandBar";

import Strings from "./strings";

class ExcelPaneContacts extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        };
    }

    render() {
        if (!this.props.visible) {
            return null;
        }

        return (
            <section>
                <CommandBar
                    onRefreshClick={() => this.props.refreshItems}
                    infoCalloutHeader={Strings.viewUI.contacts.callout.header}
                    infoCalloutBody={Strings.viewUI.contacts.callout.body}
                />
            </section>
        );
    }
}

export default ExcelPaneContacts;
