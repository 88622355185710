import * as React from "react";
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';
import { IconButton, Text } from 'office-ui-fabric-react';
import Strings from "./strings";


const styles = {
    root: [
        {
            selectors: {
                ':hover': {
                    //cursor: 'pointer',
                },
                '&:hover .childElement': {
                    color: 'white'
                }
            }
        }
    ]
};


export default class ExcelHeader extends React.Component {

    constructor(props, context) {
        super(props, context);
    }

    onClick = async (event) => {
        this.props.onClick(event);
    }

    render() {
        const { activityName, activityIsMine } = this.props;
        return (
            <section style={{
                paddingBottom: '10px',
                display: 'inline-flex',
                width: '100%',
            }}>
                <IconButton
                    style={{ marginRight: "10px" }}
                    iconProps={{ iconName: 'GlobalNavButton' }}
                    title={Strings.userProfile.activities}
                    ariaLabel="Aktivitäten"
                    onClick={this.onClick} />
                <Text variant="xLarge" >
                    {activityName}
                </Text>
                {/*                 <Persona
                    styles={styles}
                    text={activityName}
                    secondaryText={activityIsMine ? Strings.userProfile.createdActivity : Strings.userProfile.invitedToActivity}
                    size={PersonaSize.size40} /> */}
            </section>
        );
    }
}
