import moment from "moment";
import Strings from "../../../shared/strings";
import { AttributeName } from "../enums";
import {DataServices as Ds} from "../../../shared/data-services/DataServices";

type ValidationFunction = (cellValue: string) => {passed: boolean, message: string};

// regex only really searches for common mistakes
const emailValidationString = /^[^\s@#!]+@[^\s@.#!]+(.[^\s@.]+)+$/;
export const validDateInputFormats = [
    "DD.MM.YYYY HH:mm",
    "DD.MM.YYYY",
    "DD.MM",
    "DD.MM.YYYY  HH:mm:ss"
];

const validationFunctionByAttribute: Record<AttributeName, ValidationFunction> = {
    [AttributeName.startAt]: (cellValue: string) => {
        const isValidDate = validDateInputFormats.reduce((prevWasValid, currInputFormat) =>
            moment(cellValue, currInputFormat, true).isValid() || prevWasValid
            , false);
        return {
            passed: isValidDate || cellValue === "",
            message: Strings.tables.errors.attributes.startAt
        };
    },
    [AttributeName.assignee]: (cellValue) => {
        const assigneeMatch = Ds.ContactsService.getLoadedContactsForCurrentActivity().some(
            contact => cellValue === contact.email);
        return {
            passed: assigneeMatch || cellValue === "",
            message: Strings.tables.errors.attributes.assignee
        };
    },
    [AttributeName.stackName]: (cellValue) => {
        const stackMatch = Ds.StacksService.getLoadedStacksForCurrentActivity().some(stack => stack.name === cellValue)
        return {
            passed: stackMatch || cellValue === "",
            message: Strings.tables.errors.attributes.stackName
        };
    },
    [AttributeName.email]: (cellValue) => {
        let passed = emailValidationString.test(cellValue);
        return {
            passed: passed,
            message: Strings.tables.errors.attributes.email
        };
    },
    [AttributeName.open]: (cellValue) => {
        return {
            passed: cellValue === "Offen" || cellValue === "Erledigt" || cellValue === "",
            message: Strings.tables.errors.attributes.open,
        };
    },
    [AttributeName.activities]: (cellValue) => ({passed: true, message: ""}),
    [AttributeName.name]: (cellValue) => ({passed: true, message: ""}),
    [AttributeName.counterId]: (cellValue) => ({passed: true, message: ""}),
    [AttributeName.createdAt]:  (cellValue) => ({passed: true, message: ""}),
    [AttributeName.content]: (cellValue) => ({passed: true, message: ""}),
    [AttributeName.delete]: (cellValue) => ({passed: true, message: ""}),
    [AttributeName.label]: (cellValue) => ({passed: true, message: ""}),
    [AttributeName.type]: (cellValue) => ({passed: true, message: ""}),
    [AttributeName.link]: (cellValue) => ({passed: true, message: ""}),
    [AttributeName.stackLink]: (cellValue) => ({passed: true, message: ""}),
    [AttributeName.status]: (cellValue) => ({passed: true, message: ""}),
    [AttributeName.id]: (cellValue) => ({passed: true, message: ""}),
    [AttributeName.parameters]: (cellValue) => ({passed: true, message: ""}),
};

export function validateAttribute(attribute: any, cellValue: string) {
    if (!attribute.startsWith("parameters")) {
        return validationFunctionByAttribute[attribute](cellValue);
    } else {
        // TODO Numbers 
        return {passed: true, message: ""}
    }

}