import {API, graphqlOperation} from "aws-amplify";
import {
    listCards,
    closeCard,
    createCard,
    deactivateCard,
    openCard,
    updateCard,
    updateCardDate,
    changeCardStack
} from "./graphql";
import {GraphQLResult} from "@aws-amplify/api-graphql";
import CardDto, {CardServerResponseDto, InputCard} from "./CardDto";
import { v4 as uuidv4 } from 'uuid';
import {DataServices, FilterSettings} from "./DataServices";

class CardsService {
    private cardsByActivityId: Record<string, CardDto[]> = {};
    private nextTokenByActivityId: Record<string, string> = {};
    private responseMessages: any[] = [];
    constructor(private readonly maxCardLimit: number = maxCardLimit) {}

    allLoadedInActivity(activityId: string) {
        return this.nextTokenByActivityId[activityId] !== undefined;
    }

    clearCache() {
        this.cardsByActivityId = {};
    }

    getLoadedCardById(cardId: string): CardDto | null {
        for (const cardList of Object.values(this.cardsByActivityId)) {
            const foundCard = cardList.find(card => card.id === cardId);
            if (foundCard) {
                return foundCard;
            }
        }
        return null;
    }

    getLoadedCardsForCurrentActivity(): CardDto[] {
        const activeActivityId = DataServices.ActivitiesService.getActiveActivityId();
        return this.cardsByActivityId[activeActivityId];
    }

    async getCardsForCurrentActivity(filter?: FilterSettings): Promise<CardDto[]> {
        const activeActivityId = DataServices.ActivitiesService.getActiveActivityId();
        return this.getCardsForActivity(activeActivityId, filter);
    }

    async getCardsForActivity(activityId: string, filters?: FilterSettings): Promise<CardDto[]> {
        if (!this.cardsByActivityId[activityId] || filters !== undefined) {
            await this.loadCardsForActivity(activityId, filters);
        }
        return this.cardsByActivityId[activityId];
    }

    async loadMoreCardsForActivity(activityId: string, filters?: FilterSettings) {
        await this.loadCardsForActivity(activityId, filters);
    }

    async deleteCard(cardId: string) {
        try {
            const result = await API.graphql(graphqlOperation(deactivateCard, {cardId}));
            this.responseMessages.push(result);
        }
        catch (e) {
            this.responseMessages.push(e);
        }
    }

    async createCard(card: InputCard) {
       try {
           const activityId = DataServices.ActivitiesService.getActiveActivityId();
           var input = {
                id: "card_" + uuidv4(),
                name: card.name,
                stackId: card.stackId ? card.stackId : activityId.replace("activity","stack"),
                content: card.content,
                startAt: card.startAt,
                createdAt: (new Date()).toISOString(),
                activityId: activityId,
            };
            if (card.parameters) input.parameters = JSON.stringify(card.parameters);
            console.log(input)
            if (card.assigneeId) input.assigneeId = card.assigneeId;
           this.responseMessages.push(await API.graphql(graphqlOperation(createCard, { input: input})));
       }
       catch (e) {
           this.responseMessages.push(e);
       }
    }

    async updateCard(cardId: string, updatedCard: InputCard) {
        const oldCard = this.getLoadedCardById(cardId);
        if (!oldCard) {
            throw new Error(`Card with id ${cardId} either doesn't exist or hasn't yet been loaded!`)
        }
        await this.updateIfStackChanged(oldCard, updatedCard);
        await this.updateCardStatus(oldCard, updatedCard);
        await this.updateCardDate(oldCard, updatedCard);
        await this.updateGenericCardFields(oldCard, updatedCard);
    }

    getResponseMessages() {
        return this.responseMessages.slice();
    }

    clearResponseMessages() {
        this.responseMessages = [];
    }

    private async updateGenericCardFields(oldCard: CardDto, updatedCard: InputCard) {
         var input = {
            cardId: oldCard.id,
            name: updatedCard.name,
            content: updatedCard.content,
            assigneeId: updatedCard.assigneeId ? updatedCard.assigneeId : null
        };
        if (updatedCard.parameters) input.parameters = JSON.stringify(updatedCard.parameters);
        this.responseMessages.push(await API.graphql(graphqlOperation(updateCard, input)));
    }

    private async updateIfStackChanged(oldCard: CardDto, updatedCard: InputCard) {
        if (oldCard.stackId !== updatedCard.stackId) {
            try {
                var input = {
                    activityId: this.findActivityIdByCardId(oldCard.id),
                    cardId: oldCard.id,
                    from: oldCard.stackId,
                    to: updatedCard.stackId,
                };
                console.log(input)
                this.responseMessages.push(await API.graphql(graphqlOperation(changeCardStack, input)))
            }
            catch (e) {
                this.responseMessages.push(e);
            }
        }
    }

    private async updateCardDate(oldCard: CardDto, updatedCard: InputCard) {
        try {
            this.responseMessages.push(await API.graphql(graphqlOperation(updateCardDate, {cardId: oldCard.id, startAt: updatedCard.startAt})));
        }
        catch (e) {
            this.responseMessages.push(e);
        }
    }

    private async updateCardStatus(oldCard: CardDto, updatedCard: InputCard) {
        try {
            if (updatedCard.open && !oldCard.open) {
                this.responseMessages.push(await API.graphql(graphqlOperation(openCard, {cardId: oldCard.id})));
            } else if (!updatedCard.open && oldCard.open) {
                this.responseMessages.push(await API.graphql(graphqlOperation(closeCard, {cardId: oldCard.id})));
            }
        }
        catch (e) {
            this.responseMessages.push(e);
        }
    }

    private findActivityIdByCardId(cardId: string): string | null {
        for (const [activityId, cardList] of Object.entries(this.cardsByActivityId)) {
            if (cardList.some(card => card.id === cardId)) {
                return activityId;
            }
        }
        return null;
    }

    private serverDataToInternalDto(cardServerResponseDto: CardServerResponseDto): CardDto {
        const card: CardDto = {
            assigneeId: cardServerResponseDto.assigneeId,
            content: cardServerResponseDto.content ?? "",
            updatedAt: cardServerResponseDto.updatedAt,
            createdAt: cardServerResponseDto.createdAt,
            remindAt: cardServerResponseDto.remindAt,
            startAt: cardServerResponseDto.startAt,
            createdBy: cardServerResponseDto.createdBy,
            id: cardServerResponseDto.id,
            name: cardServerResponseDto.name,
            open: cardServerResponseDto.open,
            parentId: cardServerResponseDto.parentId,
            stackId: cardServerResponseDto.stackId,
            counterId: cardServerResponseDto.counterId,
        };
        if (cardServerResponseDto.parameters) {
            const parsedParameters = JSON.parse(cardServerResponseDto.parameters)
            card.parameters = parsedParameters;
        }
        return card;
    }

    private async loadCardsForActivity(activityId: string, filters: FilterSettings = {}) {
        this.cardsByActivityId[activityId] = [];
        try {
            console.log(filters)

            var resultCardItems: any[] = [];
            var nextToken = null;

            const result = (await API.graphql(graphqlOperation(listCards, {
                activityId: activityId,
                startAt: filters.startAt,
                endAt: filters.endAt,
                limit: filters.limit ?? 500,
                stackId: filters.stackId,
                assigneeId: filters.contactId,
                nextToken: this.nextTokenByActivityId[activityId] ?? nextToken,
            }))) as GraphQLResult<any>;

            if (result.data.listCards) {
                console.log(result.data.listCards)
                this.nextTokenByActivityId[activityId] = result.data.listCards.nextToken;
                nextToken = result.data.listCards.nextToken;
                resultCardItems.push(...result.data.listCards.items)
            } 

            while (nextToken) {
                const result = (await API.graphql(graphqlOperation(listCards, {
                    activityId: activityId,
                    startAt: filters.startAt,
                    endAt: filters.endAt,
                    limit: filters.limit ?? 500,
                    stackId: filters.stackId,
                    assigneeId: filters.contactId,
                    nextToken: this.nextTokenByActivityId[activityId] ?? nextToken,
                }))) as GraphQLResult<any>;

                if (result.data.listCards) {
                    console.log(result.data.listCards)
                    this.nextTokenByActivityId[activityId] = result.data.listCards.nextToken;
                    nextToken = result.data.listCards.nextToken;
                    resultCardItems.push(...result.data.listCards.items)
                } else {
                    nextToken = null;
                }
            }

            if (resultCardItems) {
                var resultCards: CardDto[]= [];
                const sortedByCreatedAt =  resultCardItems.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
                for (const card of sortedByCreatedAt) {
                    if (filters.closed || card.open){
                        resultCards.push(this.serverDataToInternalDto(card));
                    }
                }
                this.cardsByActivityId[activityId] = resultCards;
            }
        }
        catch (e) {
            this.responseMessages.push(e);
        }
    }

}



export default CardsService;