import {AttributeName, DataTableRowState} from "../enums";
import {ColumnSchema} from "../schemata";
import {AbstractDataTable} from "./DataTable";
import ContactDto from "../../../shared/data-services/ContactDto";
import ContactsTableRow from "./ContactsTableRow";
import {DataServices as Ds, DataServices} from "../../../shared/data-services/DataServices";

const contactsTableColumnsSchema: ColumnSchema[] = [
    {
        label: AttributeName.email,
        displayName: "Email",
        required: true,
        updateData: false,
        createData: true,
    }
];

const contactsTableStyle = {
    style: "TableStyleLight18",
    showBandedRows: false
};

class ContactsTable extends AbstractDataTable<ContactsTableRow, ContactDto> {
    constructor() {
        super(
            "Kontakte_Tabelle",
            contactsTableStyle,
            {col: 0, row: 0},
            contactsTableColumnsSchema,
            0,
            []
        );
    }

    selectSchemaForActivity(){

	}

    async refresh() {
        DataServices.ContactsService.clearCache();
        const newStacks = await DataServices.ContactsService.getContactsForCurrentActivity();
        this.appendNewRowsFromDtos(newStacks);
    }

    async uploadInput(progressCallback: Function): Promise<void> {
        let syncErrors = 0;
        let syncSuccesses = 0;
        let syncOutstanding = this.rowCountByType([DataTableRowState.CREATE_DATA, DataTableRowState.UPDATE_DATA]);
        for (const row of this.rows) {
            try {
                const rowId = row.getId();
                if (!rowId) {
                    await Ds.ContactsService.inviteContact(row.toInputContact());
                }
                else if (row.isMarkedForDeletion()) {
                    await Ds.ContactsService.deleteContact(rowId);
                }
                syncSuccesses += 1;
            }
            catch (e) {
                syncErrors += 1;
            }
            syncOutstanding -= 1;
            progressCallback(syncErrors, syncSuccesses, syncOutstanding);
        }
    }

    getService() {
        return DataServices.ContactsService;
    }

    rowIsDuplicate() {
        return false;
    }

    protected createRow(newData: string[] | ContactDto) {
        return new ContactsTableRow(newData, contactsTableColumnsSchema);
    }
}

export default ContactsTable;