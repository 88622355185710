import {AttributeName} from "../enums";
import {ColumnSchema} from "../schemata";
import CardDto, {InputCard} from "../../../shared/data-services/CardDto";
import DataTableRow from "./DataTableRow";
import {DataServices} from "../../../shared/data-services/DataServices";
import moment from "moment";
import {validDateInputFormats} from "./AttributeValidator";
import {Decimal} from 'decimal.js';

class CardsTableRow extends DataTableRow<CardDto> {
    constructor(sourceData: CardDto | string[], columnSchema: ColumnSchema[]) {
        super(sourceData, columnSchema);
        if (!Array.isArray(sourceData)) {

            const counterIdCell = this.getCellByAttribute(AttributeName.counterId);
            if (counterIdCell) counterIdCell.value = "#"+ (sourceData.counterId ?? "");

            const openCell = this.getCellByAttribute(AttributeName.open);
            if(openCell) openCell.value = sourceData.open ? "Offen" : "Erledigt";

            const startAtCell = this.getCellByAttribute(AttributeName.startAt);
            if (startAtCell){
                const formattedDate = sourceData.startAt ? 
                moment(sourceData.startAt, moment.ISO_8601).format(validDateInputFormats[0]) :
                "";
                startAtCell.value = formattedDate;
            }

            const createdAtCell = this.getCellByAttribute(AttributeName.createdAt);
            if (createdAtCell){
                const formattedDate = sourceData.createdAt ? 
                moment(sourceData.createdAt, moment.ISO_8601).format(validDateInputFormats[0]) :
                "";
                createdAtCell.value = formattedDate;
            }

            if (sourceData.parameters){
                for (var param in sourceData.parameters) {
                    const paramCell = this.getCellByName("parameters"+param);
                    if (paramCell) paramCell.value = sourceData.parameters[param];
                }
            }

            this.replaceStackIdWithName(sourceData.stackId);
            this.replaceContactIdWithEmail(sourceData.assigneeId);
        }
    };

    private replaceStackIdWithName(stackId: string) {
        const stackNameCell = this.getCellByAttribute(AttributeName.stackName);
        if (stackNameCell) {
            const stackName = DataServices.StacksService.getLoadedStackById(stackId)?.name;
            stackNameCell.value = stackName ?? "";
        }
    }

    private getIdForStackName() {
        const stackNameCell = this.getCellByAttribute(AttributeName.stackName);
        if (stackNameCell) {
            const stacksInActivity = DataServices.StacksService.getLoadedStacksForCurrentActivity();
            const foundStack = stacksInActivity.find(contact => contact.name === stackNameCell.value);
            return foundStack?.id ?? "";
        } else {
            return "";
        }
    }

    private replaceContactIdWithEmail(contactId: string) {
        const assigneeCell = this.getCellByAttribute(AttributeName.assignee);
        if (assigneeCell) {
            const contactEmail = DataServices.ContactsService.getLoadedContactById(contactId)?.email;
            assigneeCell.value = contactEmail ?? "";
        }
    }

    private getIdForContactEmail() {
        const assigneeCell = this.getCellByAttribute(AttributeName.assignee);
        if (assigneeCell) {
            const contactsInActivity = DataServices.ContactsService.getLoadedContactsForCurrentActivity();
            const foundContact = contactsInActivity.find(contact => contact.email === assigneeCell!.value);
            return foundContact?.id ?? "";
        } else {
            return "";
        }
    }

    toInputCard(): InputCard {
        const inputCard = {};
        const inputParameters = {};
        this.cells.forEach(cell => {
            if (cell.name.startsWith("parameters")) {
                var tmpName = cell.name.replace("parameters", "");
                inputParameters[tmpName] = cell.value;
                if (cell.type && cell.type == "Number" && cell.value) {
                    var cellValue = cell.value.replace(',','.');
                    var decimalValue = new Decimal(cellValue);
                    if (decimalValue != null) inputParameters[tmpName] = decimalValue.toString();
                }
            } else {
                inputCard[cell.name] = cell.value;
            }
        });
        if (!(Object.keys(inputParameters).length === 0))
        (inputCard as InputCard).parameters = inputParameters;
        //(inputCard as InputCard).name = this.getCellByAttribute(AttributeName.name)?.value;
        (inputCard as InputCard).open = inputCard[AttributeName.open] === "Offen";
        (inputCard as InputCard).assigneeId = this.getIdForContactEmail();
        (inputCard as InputCard).stackId = this.getIdForStackName();
        (inputCard as InputCard).startAt = moment(inputCard[AttributeName.startAt], validDateInputFormats).toISOString();
        return inputCard as InputCard;
    }

}

export default CardsTableRow;