import {Dialog, DialogFooter, DialogType} from "office-ui-fabric-react/lib/Dialog";
import {DefaultButton, PrimaryButton} from "office-ui-fabric-react/lib/Button";
import * as React from "react";
import Strings from "./strings";

function ExcelLoseDataDialog(props) {
    const {
        show,
        onCancel,
        onConfirm,
        warningMessage
    } = props;

    return (
        <Dialog
            hidden={!show}
            onDismiss={onCancel}
            dialogContentProps={{
                type: DialogType.normal,
                title: Strings.dialogs.confirmLoseData.title,
                subText: Strings.dialogs.confirmLoseData.subtext,
            }}
            modalProps={{
                isBlocking: true,
                styles: { main: { maxWidth: 450 } }
            }}
        >
            {warningMessage}
            <DialogFooter>
                <PrimaryButton
                    onClick={onCancel}
                    text={Strings.dialogs.confirmLoseData.cancel}
                />
                <DefaultButton
                    onClick={onConfirm}
                    text={Strings.dialogs.confirmLoseData.confirm}
                />
            </DialogFooter>
        </Dialog>
    );
}

export default ExcelLoseDataDialog;