import {AttributeName, DataTableRowState} from "../enums";
import {ColumnSchema} from "../schemata";
import {AbstractDataTable} from "./DataTable";
import CardsTableRow from "./CardsTableRow";
import CardDto from "../../../shared/data-services/CardDto";
import {FilterSettings, DataServices as Ds, DataServices} from "../../../shared/data-services/DataServices";

var cardsTableColumnsSchema: ColumnSchema[] = [
    {
        label: AttributeName.counterId,
        displayName: "Id",
        required: false,
        updateData: true,
        createData: true
    },
    {
        label: AttributeName.name,
        displayName: "Name",
        required: false,
        updateData: true,
        createData: true
    },
    {
        label: AttributeName.assignee,
        displayName: "Zugewiesen an",
        options: "CONTACTS",
        required: false,
        updateData: true,
        createData: true
    },
    {
        label: AttributeName.stackName,
        displayName: "Stack",
        options: "STACKS",
        required: false,
        updateData: true,
        createData: true
    },
    {
        label: AttributeName.startAt,
        displayName: "Termin",
        required: false,
        updateData: true,
        createData: true
    },
    {
        label: AttributeName.content,
        displayName: "Beschreibung",
        required: false,
        updateData: true,
        createData: true
    },
    {
        label: AttributeName.createdAt,
        displayName: "Erstellt",
        required: false,
        updateData: true,
        createData: false,
        type: "Date"
    },
    {
        label: AttributeName.open,
        displayName: "Erledigt",
        options: ["Erledigt", "Offen"],
        required: false,
        updateData: true,
        createData: false
    }
];

const cardsTableStyle = {
    style: "TableStyleLight18",
    showBandedRows: false
};

class CardsTable extends AbstractDataTable<CardsTableRow, CardDto> {
    constructor() {
        super(
            "Cards_Tabelle",
            cardsTableStyle,
            {col: 0, row: 0},
            cardsTableColumnsSchema,
            0,
            []
        );
    }

    selectSchemaForActivity(){
        const activity = Ds.ActivitiesService.getActiveActivity();
        const isRootActivity = activity?.type == "ROOT";
        const parameters = activity?.appendix?.parameters;

		if(isRootActivity) {
            const filteredSchema = cardsTableColumnsSchema.filter(col => {
                return col.label == AttributeName.counterId ||
                col.label == AttributeName.name ||
                col.label == AttributeName.startAt ||
                col.label == AttributeName.open ||
                col.label == AttributeName.content;
            });
            this.updateSchema(filteredSchema)
        } else if (parameters && Array.isArray(parameters)) {
            const customSchema = cardsTableColumnsSchema.slice();
            const sortedParams = parameters.map(param => {
                    return  {
                        label: "parameters"+param.id,
                        displayName: param.name,
                        type: param.type,
                        required: false,
                        updateData: true,
                        createData: true,
                        active: param.active
                    } 
                })
                .filter(p => p.active);
        
            customSchema.splice(customSchema.length-2,0,...sortedParams);
            this.updateSchema(customSchema)
        } else {
            this.updateSchema(cardsTableColumnsSchema);
        }
	}

    async refresh(filter?: FilterSettings) {
        Ds.CardsService.clearCache();
        const newCards = await Ds.CardsService.getCardsForCurrentActivity(filter);
        this.appendNewRowsFromDtos(newCards);
    }

    async uploadInput(progressCallback: Function) {
        let syncErrors = 0;
        let syncSuccesses = 0;
        let syncOutstanding = this.rowCountByType([DataTableRowState.CREATE_DATA, DataTableRowState.UPDATE_DATA]);
        for (const row of this.rows) {
            try {
                const rowId = row.getId();
                if (!rowId) {
                    await Ds.CardsService.createCard(row.toInputCard());
                }
                else if (row.isMarkedForDeletion()) {
                    await Ds.CardsService.deleteCard(rowId);
                }
                else if (row.userModified()) {
                    await Ds.CardsService.updateCard(rowId, row.toInputCard());
                }
                syncSuccesses += 1;
            }
            catch (e) {
                syncErrors += 1;
            }
            syncOutstanding -= 1;
            progressCallback(syncErrors, syncSuccesses, syncOutstanding);
        }
    }

    getService() {
        return DataServices.CardsService;
    }

    updateFromLoadedCards() {
        const loadedCards = Ds.CardsService.getLoadedCardsForCurrentActivity();
        this.updateRowsFromDtos(loadedCards);
    }

    protected createRow(newData: string[] | CardDto) {
        return new CardsTableRow(newData, this.columnsSchema);
    }
}

export default CardsTable;