export enum DataTableRowState {
	UNCHANGED = "UNCHANGED",
	UPDATE_DATA = "UPDATE_DATA",
    CREATE_DATA = "CREATE_DATA",
    ERROR = "ERROR",
}

export enum AttributeName {
    id = "id",
    counterId = "counterId",
    name = "name",
    stackName = "stackName",
    assignee = "assignee",
    startAt = "startAt",
    createdAt = "createdAt",
    content = "content",
    delete = "delete",
    email = "email",
    open = "open",
    activities = "activities",
    status = "status",
    label = "label",
    type = "type",
    link = "link",
    stackLink = "stackLink",
    parameters = "parameters"
}


export enum AttributeProperty {
    createData = "createData",
    updateData = "updateData",
    unique = "unique"
}