import * as React from "react";
import {MessageBar, MessageBarType} from "office-ui-fabric-react";
import Strings from "./strings";
import formatUnicorn from "format-unicorn/safe";

function rowPl(count) {
    return count === 1 ? Strings.viewUI.changeMessages.rowSg : Strings.viewUI.changeMessages.rowPl;
}

function ExcelTableChangeMessages({addedCount, editedCount, errorCount, toDeleteCount}) {
    return (
        <div>
            {errorCount > 0 && (
                <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
                    {errorCount} {rowPl(errorCount)} {Strings.viewUI.changeMessages.error}
                </MessageBar>
            )}
            {addedCount > 0 && (
                <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>
                    {addedCount} {rowPl(addedCount)} {Strings.viewUI.changeMessages.added}
                </MessageBar>
            )}
            {editedCount - toDeleteCount > 0 && (
                <MessageBar messageBarType={MessageBarType.warning} isMultiline={false}>
                    {editedCount - toDeleteCount} {rowPl(editedCount)} {Strings.viewUI.changeMessages.edited}
                </MessageBar>
            )}
            {toDeleteCount > 0 && (
                <MessageBar messageBarType={MessageBarType.warning} isMultiline={false}>
                    {toDeleteCount} {rowPl(editedCount)}{" "}
                    {formatUnicorn(Strings.viewUI.changeMessages.toBeDeleted, toDeleteCount)}
                </MessageBar>
            )}
        </div>
    );
}
export default ExcelTableChangeMessages;
