const listCards = `query listCards ($activityId: ID, $stackId: ID, $limit: Int, $startAt: AWSDateTime, $endAt: AWSDateTime, $nextToken: String, $assigneeId: ID) {
  listCards (activityId: $activityId, stackId: $stackId, limit: $limit,  startAt: $startAt, endAt: $endAt, nextToken: $nextToken, assigneeId: $assigneeId) {
        items {
        id
        name
        content
        parentId
        createdAt
        updatedAt
        createdBy
        remindAt
        guids
        status
        open
        appendix
        news
        fileStatus
        type
        startAt
        assigneeId
        stackId
        parameters
        counterId
      }
      nextToken
    }
  }`

const listStacks = `query listStacks($activityId: ID!){
  listStacks(activityId: $activityId){
    items {
      id
      name
      content
    }
  }
}`

const deactivateStack =`mutation deactivateStack($stackId: ID!) {
  deactivateStack(stackId: $stackId) {
      id
  }
}`

const getData = `query getData($id: ID!) {
    getData (id: $id) {
      id
      parentId
      name
      content
      createdAt
      createdBy
      updatedAt
      status
      guids
    }
  }`


const getProfile = `query getProfile {
  getProfile {
      id
      name
      appendix
      pro
  }
}`

const getActivityRoot = `query getActivityRoot {
  getActivityRoot {
    id
    name
    createdBy
  }
}`

const listActivities = `query listActivities {
  listActivities {
    items {
      id
      name
      type
      createdBy
      updatedAt
      activityId
      pro
      counterId
      createdByContact {
        id
        pro
        name
        appendix
      }
      contacts {
        items {
            id
            name
            appendix
            pro
            file {
                key
            }
        }
      }
      appendix
    }
  }
}`

const listTeamActivities = `query listTeamActivities {
  listTeamActivities {
      items {
        id
        name
        type
        pro
          contacts {
            items {
              id
              name
              appendix
            }
            invitations {
              parentId
              id
              name
            }
          }
      }
  }
}`

const listContacts = `query listContacts($activityId: ID!){
  listContacts(activityId: $activityId){
    items {
      id
      name
      appendix
    }
    invitations {
      parentId
      id
      name
      appendix
    }
  }
}`

const createCard = `mutation createCard($input: CreateCardInput!) {
  createCard(input: $input) {
    id
    parentId
    name
    content
    createdAt
    status
    file {
         localUri
         key
         bucket
         region
         mimeType
    }
    guids
    startAt
    counterId
  }
  }`

const updateCard = `mutation updateCard($cardId: ID!, $name: String, $content: String, $assigneeId: ID, $parameters: AWSJSON) {
  updateCard(cardId: $cardId, name: $name, content: $content, assigneeId: $assigneeId, parameters: $parameters) {
      id
      name
      content
      counterId
  }
}`

const updateCardDate = `mutation updateCardDate($cardId: ID!, $startAt: AWSDateTime) {
  updateCardDate(cardId: $cardId, startAt: $startAt) {
    name
    id
    startAt
  }
}`

const closeCard = `mutation closeCard($cardId: ID!) {
  closeCard(cardId: $cardId) {
    id
    name
    open
    counterId
  }
}`
const openCard = `mutation openCard($cardId: ID!) {
  openCard(cardId: $cardId) {
    id
    name
    open
    counterId
  }
}`

const changeCardStack = `mutation changeCardStack($activityId: ID!, $cardId: ID!, $from: ID!, $to: ID!) {
    changeCardStack(activityId: $activityId, cardId: $cardId, from: $from, to: $to) {
      name
      id
      counterId
    }
  }`

const createStack = `mutation createStack($activityId: ID!, $name: String!) {
  createStack(activityId: $activityId, name: $name) {
    id
  }
}`

const updateStack = `mutation updateStack($stackId: ID!, $name: String!) {
  updateStack(stackId: $stackId, name: $name) {
    	id
    name
    content
  }
}`

const deactivateCard = `mutation deactivateCard($cardId: ID!) {
  deactivateCard(cardId: $cardId) {
    name
    id
    counterId
  }
}`
const createActivity = `mutation createActivity($name: String!) {
  createActivity(name: $name){
      id
      name
      type
    }
  }`

const createActivityTeam = `mutation createActivityTeam($name: String!) {
  createActivityTeam(name: $name){
        id
        name
        type
      }
    }`

const businessCreateActivity = `mutation businessCreateActivity($name: String!, $type: String!, $activityId: ID!) {
    businessCreateActivity(name: $name, type: $type, activityId: $activityId) {
      id
      name
      type
    }
  }`

const disinviteContact = `mutation disinviteContact($activityId: ID!, $email: AWSEmail!) {
    disinviteContact(activityId: $activityId, email: $email) {
        id
    }
}`

const removeContactFromActivity = `mutation removeContactFromActivity($activityId: ID!, $contactId: ID!) {
  removeContactFromActivity(activityId: $activityId, contactId: $contactId) {
    id
  }
}`

const inviteContact = `mutation inviteContact($activityId: ID!, $email: AWSEmail!) {
  inviteContact(activityId: $activityId, email: $email) {
    id
  }
} `

const listContactsInvited = `query listContactsInvited($activityId: ID!){
  listContactsInvited(activityId: $activityId){
    items {
      parentId
      id
    }
  }
} `

const updateActivityToPremium = `mutation updateActivityToPremium($activityId: ID!) {
  updateActivityToPremium(activityId: $activityId) {
    id
    pro
  }
} `
const updateActivityToFree = `mutation updateActivityToFree($activityId: ID!) {
  updateActivityToFree(activityId: $activityId) {
    id
    pro
  }
} `


const getStripeSession = `query getStripeSession {
  getStripeSession
} `


const getStripePrice = `query getStripePrice ($country: String){
  getStripePrice(country: $country)
}`

const getStripeCheckoutSession = `query getStripeCheckoutSession {
  getStripeCheckoutSession
} `

const getStripeCustomer = `query getStripeCustomer {
  getStripeCustomer
} `

const createStripeSubscription = `mutation createStripeSubscription($paymentMethodId: String!, $street: String, $postal_code: String, $city:String, $country:String) {
  createStripeSubscription(paymentMethodId: $paymentMethodId, street: $street, postal_code: $postal_code, city: $city, country: $country)
} `

const updateActivityParameters = `mutation updateActivityParameters($activityId: ID!, $parameters: AWSJSON) {
  updateActivityParameters(activityId: $activityId, parameters: $parameters) {
    id
  }
}`

const updateActivity = `mutation updateActivity($activityId: ID!, $name: String, $stackIds: [ID]) {
  updateActivity(activityId: $activityId, name: $name, stackIds: $stackIds) {
      id
  }
}`


export {
  createCard,
  listCards,
  updateCard,
  updateCardDate,
  closeCard,
  openCard,
  changeCardStack,
  listStacks,
  getData,
  getProfile,
  getActivityRoot,
  listActivities,
  listContacts,
  listContactsInvited,
  listTeamActivities,
  createActivity,
  createActivityTeam,
  createStack,
  updateStack,
  deactivateStack,
  businessCreateActivity,
  inviteContact,
  deactivateCard,
  updateActivityToPremium,
  updateActivityToFree,
  updateActivityParameters,
  updateActivity,
  removeContactFromActivity,
  disinviteContact,
  getStripeCheckoutSession,
  getStripeSession,
  getStripeCustomer,
  createStripeSubscription,
  getStripePrice
};