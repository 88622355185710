import {ColumnSchema} from "../schemata";
import DataTableRow from "./DataTableRow";
import StackDto, {InputStack} from "../../../shared/data-services/StackDto";

class StacksTableRow extends DataTableRow<StackDto> {
    constructor(sourceData: StackDto | string[], columnSchema: ColumnSchema[]) {
        super(sourceData, columnSchema);
    };

    toInputStack(): InputStack {
        const inputStack = {};
        this.cells.forEach(cell => inputStack[cell.name] = cell.value);
        return inputStack as InputStack;
    }
}

export default StacksTableRow;
