import { Persona, PersonaSize } from "office-ui-fabric-react/lib/Persona";
import { Separator } from "office-ui-fabric-react/lib/Separator";
import { Link } from "react-router-dom";
import { CommandBarButton, ActionButton } from "office-ui-fabric-react";
import Activities from "./WebAppActivities";
import { Panel } from "office-ui-fabric-react/lib/Panel";
import React from "react";
import { propStyle } from "aws-amplify-react";
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './data-services/aws-exports';

function ActivitiesPanel({ web, activities, name, email, profileId, selectActivityById, selectPremium, visible, hide, onCreate, onSignOut }) {

    return (
        <>
            <Panel
                isBlocking={false}
                headerText=""
                isOpen={visible}
                onDismiss={() => hide()}
                closeButtonAriaLabel="Close"
                customWidth={'400px'}
                type={8}
                styles={{
                    scrollableContent: {
                        //overflow: "visible"
                    },
                    content: {
                        overflow: "visible",
                        padding: 0
                    },
                    main: {
                        boxShadow: '0 1.6px 3.6px 0 rgba(0,0,0,.132), 0 0.3px 0.9px 0 rgba(0,0,0,.108)'
                    }
                }}
            >
                <Persona
                    text={name}
                    size={PersonaSize.size40}
                    secondaryText={email ?? ''}
                    optionalText={''}
                    styles={{
                        root: {
                            paddingLeft: 20
                        }
                    }}
                    imageUrl={'https://' +
                        awsconfig.aws_content_delivery_bucket +
                        '.s3.' +
                        awsconfig.aws_content_delivery_bucket_region +
                        '.amazonaws.com/public/'
                        + profileId}
                />
                <ActionButton
                    text="Abmelden"
                    onClick={() => onSignOut()}
                    styles={{
                        root: {
                            height: 24,
                            marginLeft: 70,
                            paddingLeft: 0
                        },
                        label: {
                            marginLeft: 0
                        }
                    }}
                />
                <Separator />
                <Link to="/">
                    <CommandBarButton
                        iconProps={{ iconName: 'Contact' }}
                        text="Mein Konto"
                        onClick={selectPremium}
                        styles={{
                            root: {
                                height: 44,
                                width: '100%',
                                textAlign: 'left',
                                paddingLeft: 20
                            }
                        }}
                    />
                </Link>
                <Separator />
                <Activities
                    web={web}
                    activities={activities}
                    profileId={profileId}
                    onCreate={onCreate}
                    onClick={selectActivityById}
                />
            </Panel>
        </>
    );
}

export default ActivitiesPanel;
